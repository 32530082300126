/**
 * @file   src\components\SwapShiftOfferedCard.tsx
 * @brief  Component for swap shift offers.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React from 'react';
import Strings from '../assests/strings/Strings.json';
import '../styles/ShiftCard.scss';
import { Card, Col, Row, moment } from './ThirdPartyComponents';
import { ISwapOfferedCard } from '../interfaces/generalInterface';
import Published from '../assests/icons/Publish';
import Draft from '../assests/icons/Draft';
import DayShift from '../assests/icons/DayShift';
import NightShift from '../assests/icons/NightShift';
import MidShift from '../assests/icons/MidShift';
import KKICheckbox from './KKICheckbox';
import { ShiftTypes } from '../utils/enums';
import { DATE_FORMAT_MM_DD_YYYY_DDD, DATE_FORMAT_HHMMA, NUMBER_0 } from '../utils/constants';

const SwapShiftOfferedCard = ({ swapShifts, handleCheckboxChange }: ISwapOfferedCard) => {
  // Handle checkbox button change
  const onChangeRadioButtion = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      handleCheckboxChange(swapShifts.shiftId, swapShifts?.startDate);
    }
  };

  // Determine what to show for color group based on required values
  const renderOpenSlots = () => {
    const { yellowRequired, blueRequired, redRequired, whiteRequired, yellowCount, blueCount, redCount, whiteCount } = swapShifts || {};

    // Check if all required values are zero
    if (yellowRequired === NUMBER_0 && blueRequired === NUMBER_0 && redRequired === NUMBER_0) {
      return <span>None</span>;
    }

    return (
      <>
        {(yellowRequired !== NUMBER_0 || yellowCount !== NUMBER_0) && (
          <span className="openslot yellow">
            {yellowCount || NUMBER_0}/{yellowRequired || NUMBER_0}
          </span>
        )}
        {(blueRequired !== NUMBER_0 || blueCount !== NUMBER_0) && (
          <span className="openslot blue">
            {blueCount || NUMBER_0}/{blueRequired || NUMBER_0}
          </span>
        )}
        {(redRequired !== NUMBER_0 || redCount !== NUMBER_0) && (
          <span className="openslot red">
            {redCount || NUMBER_0}/{redRequired || NUMBER_0}
          </span>
        )}
        {(whiteRequired !== NUMBER_0 || whiteCount !== NUMBER_0) && (
          <span className="openslot white">
            {whiteCount || NUMBER_0}/{whiteRequired || NUMBER_0}
          </span>
        )}
      </>
    );
  };

  return (
    <Col className="shiftcard-main shiftdetails-card">
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Col className="d-flex align-items-center justify-content-between">
            <div>
              {swapShifts?.shiftType === ShiftTypes.DayShift ? <DayShift /> : ''}
              {swapShifts?.shiftType === ShiftTypes.NightShift ? <NightShift /> : ''}
              {swapShifts?.shiftType === ShiftTypes.EveningShift ? <MidShift /> : ''}
              {swapShifts?.shiftName} - {moment(swapShifts?.shiftFromTime).format(DATE_FORMAT_MM_DD_YYYY_DDD)}
            </div>
            <div>
              <KKICheckbox id={swapShifts.shiftId.toString()} value={swapShifts.shiftId.toString()} name="kkicheckbox" checkBoxType="radio" onChange={onChangeRadioButtion} />
            </div>
          </Col>
        </Card.Header>
        <Card.Body className="shift-details">
          <Col className="shift-details-sub">
            <Row>
              <Col xs={6} lg={6} xl={3} xxl={3} className="bb-0-min-lg">
                {Strings.Schedule.Time}
                <span>
                  {moment.utc(swapShifts?.shiftFromTime).format(DATE_FORMAT_HHMMA)} - {moment.utc(swapShifts?.shiftToTime).format(DATE_FORMAT_HHMMA)}
                </span>
              </Col>
              <Col xs={6} lg={6} xl={3} xxl={3} className="bb-0-min-lg">
                {Strings.Schedule.CASlots}
                <span>
                  {' '}
                  {swapShifts?.assignedCACount}/{swapShifts?.totalCACount}
                </span>
              </Col>
              <Col xs={5} lg={6} xl={3} xxl={3} className="border-0">
                {Strings.Schedule.LDOpenslot}
                <span>
                  {' '}
                  {swapShifts?.lightDutyCount}/{swapShifts?.lightDutyRequired}
                </span>
              </Col>
              <Col xs={7} lg={6} xl={3} xxl={3} className="openslot-main ">
                {Strings.Schedule.OpenSlot}
                <label htmlFor="Open slot">{renderOpenSlots()}</label>
              </Col>
            </Row>
          </Col>
          <Row>
            <Col className="d-flex align-items-center">
              <div className="status">
                {swapShifts?.published ? (
                  <span className="published">
                    <Published /> {Strings.Filter.Published}
                  </span>
                ) : (
                  <span className="draft">
                    <Draft /> {Strings.Filter.Draft}
                  </span>
                )}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};
export default SwapShiftOfferedCard;
