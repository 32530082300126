/**
 * @file   src\containers\CA\ShiftDetails.tsx
 * @brief  Shift details page.
 * @date   Nov, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { getProfile } from '../../../store/actions/userAction';
import { getDataFromStorage } from '../../../utils/helper';
import { Button, Col, Link, Modal, useEffect, useParams, useState, useNavigate, moment, useLocation } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import Arrow from '../../../assests/icons/DownArrow';
import ShiftDetailsCard from '../../../components/ShiftDetailsCard';
import { MyScheduleActions } from '../../../utils/enums';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';
import { assignCA, callOutShift, getShiftDetails, revertAllScheduleState, unassignCA } from '../../../store/actions/manageCAScheduleAction';
import { IAssignCARequest, ICallOutShiftRequest, IUnassignCARequest } from '../../../interfaces/scheduleInterface';
import { MessageToaster } from '../../../utils/toastUtils';
import { CURRENT_DATE_FORMAT_WITH_OFFSET, HTTP_STATUS_200, NUMBER_0, NUMBER_EMPTY, OTHER_REASON_LABEL, STORAGE_USER } from '../../../utils/constants';
import Loader from '../../../components/Loader';
import { PROFILE_FETCH_FAILD } from '../../../messages/validationMessages';
import { getCalloutReasons, isShiftDateISHoliday, revertAllCommonState } from '../../../store/actions/commonAction';
import { resetShiftsDefaultState } from '../../../store/slices/manageCAScheduleSlice';
import KKISelect from '../../../components/KKISelect';
import KKIInput from '../../../components/KKIInput';
import { IObject } from '../../../interfaces/generalInterface';
import { ICalloutReasons } from '../../../interfaces/commonInterface';
import { validateForm } from '../../../utils/validationHelper';
import { CALLOUT_REASON_SCHEMA } from '../../../validations/manageScheduleSchema';
// Toast object creation.
const toast = new MessageToaster();

const ShiftDetails = () => {
  // Create route param object to access the route parameters.
  const params = useParams();

  // Create current location object.
  const location = useLocation();

  // Navigate object creation.
  const navigate = useNavigate();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access location state variables.
  const filterItems = location.state?.filterItems || null;

  // Access redux state variables.
  const {
    isShiftDetailsLoading,
    shiftDetails,
    isUnassignShiftLoading,
    isUnassignShiftSuccess,
    unassignShiftErrorCode,
    unassignShiftMessage,
    isCallOutShiftLoading,
    isCallOutShiftSuccess,
    callOutShiftErrorCode,
    callOutShiftMessage,
    isAssignShiftLoading,
    isAssignShiftSuccess,
    assignShiftErrorCode,
    assignShiftMessage,
  } = useAppSelector((state: RootState) => state.manageCASchedule);
  const {
    isShiftHolidayLoading,
    isShiftHolidaySuccess,
    shiftHolidayErrorCode,
    shiftHolidayMessage,
    isHoliday,
    isFetchCalloutReasonSuccess,
    fetchCalloutReasonErrorCode,
    fetchCalloutReasonMessage,
    callOutReasons,
  } = useAppSelector((state: RootState) => state.common);
  const { profileInfo } = useAppSelector((state: RootState) => state.user);

  // Access userInfo from the storage.
  const userInfo = getDataFromStorage(STORAGE_USER);

  // Initialize component stat variables.
  const [shiftTab, setShiftTab] = useState<number>(0);
  const [unAssignPopup, showUnAssignPopup] = useState<boolean>(false);
  const [callOutPopup, showCallOutPopup] = useState<boolean>(false);
  const [assignPopup, showAssignPopup] = useState<boolean>(false);
  const [unAssignRequest, setUnAssignRequest] = useState<IUnassignCARequest | null>(null);
  const [callOutShiftRequest, setCallOutShiftRequest] = useState<ICallOutShiftRequest | null>(null);
  const [assignRequest, setAssignRequest] = useState<IAssignCARequest | null>(null);
  const [holidayConfirmPopup, showHolidayConfirmPopup] = useState<boolean>(false);
  const [callOutReasonList, setCallOutReasonList] = useState<IObject[]>([]);
  const [selectedReason, setSelectedReason] = useState<IObject | null>(null);
  const [callOutReasonText, setCallOutReasonText] = useState<string>('');
  const [errorFields, setErrorFields] = useState<IObject | null>(null);

  // Component initial loading.
  useEffect(() => {
    /* Access and set the location url parameters. */
    const type: number = params.type ? Number(params.type) : 0;
    const shiftId: number = params.shiftId ? Number(params.shiftId) : 0;
    setShiftTab(type);
    dispatch(getProfile());
    dispatch(getShiftDetails({ shiftId, currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET) }));
    // On unmount reset states
    return () => {
      dispatch(resetShiftsDefaultState());
      dispatch(revertAllScheduleState());
    };
  }, [isUnassignShiftSuccess, isCallOutShiftSuccess, isAssignShiftSuccess]);

  // Unassign shift api response state change.
  useEffect(() => {
    if (isUnassignShiftSuccess && unassignShiftErrorCode === HTTP_STATUS_200 && unassignShiftMessage) {
      toast.toastSuccess(unassignShiftMessage);
      navigate(`/home/${shiftTab}`);
    } else if (!isUnassignShiftSuccess && unassignShiftErrorCode > HTTP_STATUS_200 && unassignShiftMessage) {
      toast.toastError(unassignShiftMessage);
    }
  }, [isUnassignShiftSuccess, unassignShiftErrorCode, unassignShiftMessage]);

  // Callout reasons api response state change.
  useEffect(() => {
    if (isFetchCalloutReasonSuccess && fetchCalloutReasonErrorCode === HTTP_STATUS_200) {
      if (callOutReasons && callOutReasons.length > 0) {
        const reasonOptions = callOutReasons.map((leave: ICalloutReasons): IObject => ({ label: leave.reason, value: leave.reasonId.toString() }));
        setCallOutReasonList(reasonOptions);
        setSelectedReason(reasonOptions[0] ?? null);
      }
    } else if (!isFetchCalloutReasonSuccess && fetchCalloutReasonErrorCode > HTTP_STATUS_200 && fetchCalloutReasonMessage) {
      toast.toastError(fetchCalloutReasonMessage);
      dispatch(revertAllCommonState());
    }
  }, [isFetchCalloutReasonSuccess, fetchCalloutReasonErrorCode, fetchCalloutReasonMessage, callOutReasons]);

  // Callout shift api response state change.
  useEffect(() => {
    if (isCallOutShiftSuccess && callOutShiftErrorCode === HTTP_STATUS_200 && callOutShiftMessage) {
      toast.toastSuccess(callOutShiftMessage);
      navigate(`/home/${shiftTab}`);
    } else if (!isCallOutShiftSuccess && callOutShiftErrorCode > HTTP_STATUS_200 && callOutShiftMessage) {
      toast.toastError(callOutShiftMessage);
    }
  }, [isCallOutShiftSuccess, callOutShiftErrorCode, callOutShiftMessage]);

  // Assign CA response state change.
  useEffect(() => {
    if (isAssignShiftSuccess && assignShiftErrorCode === HTTP_STATUS_200 && assignShiftMessage) {
      toast.toastSuccess(assignShiftMessage);
    } else if (!isAssignShiftSuccess && assignShiftErrorCode > HTTP_STATUS_200 && assignShiftMessage) {
      toast.toastError(assignShiftMessage);
    }
  }, [isAssignShiftSuccess, assignShiftErrorCode, assignShiftMessage]);

  // Shift holiday checking api response state change.
  useEffect(() => {
    if (isShiftHolidaySuccess && shiftHolidayErrorCode === HTTP_STATUS_200 && shiftHolidayMessage) {
      if (isHoliday) {
        showHolidayConfirmPopup(true);
      } else {
        showAssignPopup(true);
      }
      dispatch(revertAllCommonState());
    } else if (!isShiftHolidaySuccess && shiftHolidayErrorCode > HTTP_STATUS_200 && shiftHolidayMessage) {
      toast.toastError(shiftHolidayMessage);
      dispatch(revertAllCommonState());
    }
  }, [isShiftHolidaySuccess, shiftHolidayErrorCode, shiftHolidayMessage, isHoliday]);

  // Method to un-assign shift.
  const unAssignShift = (shift: number, caId: number, shiftStart: string) => {
    setUnAssignRequest((prev) => ({
      ...prev,
      caId,
      shift,
      shiftStart,
    }));
    showUnAssignPopup(true);
  };

  // Unassign button click handler.
  const onUnAssignClick = () => {
    if (unAssignRequest) {
      dispatch(unassignCA(unAssignRequest));
    }
    showUnAssignPopup(false);
  };

  // Method to call-out shift.
  const callOutCAFromShift = (shift: number, shiftStart: string) => {
    dispatch(getCalloutReasons());
    setCallOutShiftRequest((prev) => ({
      ...prev,
      shiftId: shift,
      shiftStart,
      currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET),
      reasonId: NUMBER_0,
      description: '',
    }));
    setErrorFields(null);
    setCallOutReasonText('');
    showCallOutPopup(true);
  };

  // Callout button click handler.
  const onShiftCallOutClick = async () => {
    const callOutReasonForm = {
      callOutReasonType: selectedReason?.value ? Number(selectedReason.value) : NUMBER_EMPTY,
      reason: callOutReasonText,
    };
    const errorResult = await validateForm(callOutReasonForm, CALLOUT_REASON_SCHEMA, errorFields);
    setErrorFields(errorResult);
    if (callOutShiftRequest && Object.keys(errorResult).length === NUMBER_0) {
      const callOutRequest = { ...callOutShiftRequest };
      callOutRequest.currentTime = moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET);
      callOutRequest.reasonId = selectedReason?.value ? Number(selectedReason.value) : NUMBER_0;
      callOutRequest.description = callOutReasonText;
      dispatch(callOutShift(callOutRequest));
      showCallOutPopup(false);
    }
  };

  // Method to assign shift.
  const assignShift = (shift: number, shiftStart: string, isOnCall: boolean) => {
    if (shiftStart) {
      dispatch(getProfile()).then((result) => {
        if (getProfile.fulfilled.match(result)) {
          const fetchedProfile = result.payload?.info;
          setAssignRequest((prev) => ({
            ...prev,
            Cas: [{ caId: Number(fetchedProfile?.userId), ColorCode: Number(fetchedProfile?.colorGroup), OnCall: isOnCall, IsLD: fetchedProfile?.lightDuty }],
            shiftId: shift,
            shiftStart,
            currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET),
            overrideColorCode: true,
          }));
          dispatch(isShiftDateISHoliday({ holidayDate: shiftStart?.split('T')[0] || '' }));
        } else {
          toast.toastError(PROFILE_FETCH_FAILD);
        }
      });
    }
  };

  // Assign confirm button click handler.
  const onAssignClick = () => {
    if (assignRequest) {
      dispatch(assignCA(assignRequest));
    }
    showAssignPopup(false);
  };

  // My schedule button click handler.
  const onScheduleButtonClick = (id: number, type: number, shiftDate: string) => {
    const caId = Number(userInfo?.userId);
    if (type === MyScheduleActions.Unassign) {
      unAssignShift(id, caId, shiftDate);
    } else if (type === MyScheduleActions.Callout) {
      callOutCAFromShift(id, shiftDate);
    } else if (type === MyScheduleActions.Assign) {
      assignShift(id, shiftDate, false);
    } else if (type === MyScheduleActions.AssignAsOnCall) {
      assignShift(id, shiftDate, true);
    }
  };

  // Callout reason type dropdown select change handler.
  const onCalloutReasonSelect = (val: IObject) => {
    setSelectedReason(val);
  };

  // Callout reason input change handler.
  const onCalloutReasonChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const callOutReasonForm = {
      callOutReasonType: selectedReason?.value ? Number(selectedReason.value) : NUMBER_EMPTY,
      reason: value,
    };
    const errorResult = await validateForm(callOutReasonForm, CALLOUT_REASON_SCHEMA, errorFields);
    setErrorFields(errorResult);
    setCallOutReasonText(value);
  };

  return (
    <div>
      <div className="pageheader">
        <h1>
          <Link
            to={{ pathname: `/home/${shiftTab}` }}
            state={{
              filterItems,
            }}
            className="back-btn"
          >
            <Arrow />
          </Link>
          {Strings.Shift.ShiftDetails.Header}
        </h1>
      </div>
      <ShiftDetailsCard
        calogin
        shiftDetails={shiftDetails}
        type={shiftTab}
        isLd={profileInfo?.lightDuty || false}
        onButtonClick={(id: number, shiftDate: string | null, type: number) => onScheduleButtonClick(id, type, shiftDate || '')}
      />
      <Modal show={unAssignPopup} onHide={() => showUnAssignPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.UnAssignPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showUnAssignPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onUnAssignClick()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={callOutPopup} onHide={() => showCallOutPopup(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{Strings.Shift.Button.CallOut}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-4">
          <Col className="mb-3">
            <KKISelect
              id="reasonTypes"
              label={Strings.Label.SelectReason}
              name="reasonTypes"
              className="custom-select"
              placeholder={Strings.Label.SelectReason}
              searchvalue={false}
              options={callOutReasonList}
              value={selectedReason}
              onSelectChange={onCalloutReasonSelect}
            />
          </Col>
          {selectedReason?.label === OTHER_REASON_LABEL && (
            <Col>
              <KKIInput
                id="declineReason"
                label={Strings.Label.EnterReason}
                name="declineReason"
                type="text"
                as="textarea"
                rows={3}
                placeholder={Strings.Leave.PlaceHolder.EnterReason}
                value={callOutReasonText}
                onChange={onCalloutReasonChange}
                alert={errorFields?.reason || ''}
              />
            </Col>
          )}
          <Col className="mt-4 text-end">
            <Button variant="outline-primary" type="button" onClick={() => showCallOutPopup(false)}>
              {Strings.Button.Cancel}
            </Button>
            <Button variant="primary" type="button" className="ms-3" onClick={() => onShiftCallOutClick()}>
              {Strings.Button.Submit}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>

      <Modal show={assignPopup} onHide={() => showAssignPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{assignRequest?.Cas?.[0]?.OnCall ? Strings.Text.AssignAsOnCallPopText : Strings.Text.AssignPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showAssignPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onAssignClick()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={holidayConfirmPopup} onHide={() => showHolidayConfirmPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.HolidayConfirmPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showHolidayConfirmPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={onAssignClick} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      {(isShiftHolidayLoading || isShiftDetailsLoading || isAssignShiftLoading || isCallOutShiftLoading || isUnassignShiftLoading) && <Loader />}
    </div>
  );
};
export default ShiftDetails;
