/**
 * @file   src\store\slices\commonSlice.ts
 * @brief  This file is responsible for creating common functionalities slices to call actions and state management.
 * @date   Sep, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { ICommon } from '../../interfaces/commonInterface';
import {
  sendOtp,
  verifyOtp,
  forgotPassword,
  changePassword,
  resetPassword,
  getSchedulesAndLeaveDetails,
  revertAllCommonState,
  forgotPasswordOtpResend,
  isShiftDateISHoliday,
  getCalloutReasons,
} from '../actions/commonAction';

// Initial state.
const commonState: ICommon = {
  isVerifyOtpSuccess: false,
  isVerifyOtpLoading: false,
  verifyOtpErrorCode: 0,
  verifyOtpMessage: null,
  verifyOtpResponse: null,
  isOtpSendLoading: false,
  isOtpSendSuccess: false,
  otpSendErrorCode: 0,
  otpSendMessage: null,
  isForgotOtpSendSuccess: false,
  isForgotOtpSendLoading: false,
  forgotOtpSendErrorCode: 0,
  forgotOtpSendMessage: null,
  forgotOtp: '',
  isForgotOtpResendSuccess: false,
  isForgotOtpResendLoading: false,
  forgotOtpResendErrorCode: 0,
  forgotOtpResendMessage: null,
  forgotResendOtp: '',
  isResetPasswordLoading: false,
  isResetPasswordSuccess: false,
  resetPasswordErrorCode: 0,
  resetPasswordMessage: null,
  isChangePasswordLoading: false,
  isChangePasswordSuccess: false,
  changePasswordErrorCode: 0,
  changePasswordMessage: null,

  isUserScheduleAndLeaveDetailsSuccess: false,
  isUserScheduleAndLeaveDetailsLoading: false,
  userScheduleAndLeaveDetailsErrorCode: 0,
  userScheduleAndLeaveDetailsMessage: null,
  userScheduleAndLeaveDetails: null,

  isShiftHolidayLoading: false,
  isShiftHolidaySuccess: false,
  isHoliday: false,
  shiftHolidayErrorCode: 0,
  shiftHolidayMessage: null,

  isFetchCalloutReasonLoading: false,
  isFetchCalloutReasonSuccess: false,
  fetchCalloutReasonErrorCode: 0,
  callOutReasons: [],
  fetchCalloutReasonMessage: null,
};

// Config common slice.
export const commonSlice = createSlice({
  name: 'common',
  initialState: commonState,
  reducers: {
    resetCommonState: () => commonState,
  },
  extraReducers(builder) {
    // ********************* Start of otp verification action state handling. *********************
    // Verify otp default state.
    builder.addCase(verifyOtp.pending, (state) => {
      state.isVerifyOtpSuccess = false;
      state.isVerifyOtpLoading = true;
      state.verifyOtpErrorCode = 0;
      state.verifyOtpMessage = '';
    });
    // Verify otp success state.
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isVerifyOtpLoading = false;
      state.isVerifyOtpSuccess = true;
      state.verifyOtpErrorCode = code;
      state.verifyOtpMessage = status || '';
    });
    // Verify otp error state.
    builder.addCase(verifyOtp.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isVerifyOtpSuccess = false;
      state.isVerifyOtpLoading = false;
      state.verifyOtpErrorCode = errcode;
      state.verifyOtpMessage = error || '';
    });
    // ********************* End of otp verification action state handling. *********************

    // ********************* Start of Send otp action state handling. *********************
    // Send otp default state.
    builder.addCase(sendOtp.pending, (state) => {
      state.isOtpSendSuccess = false;
      state.isOtpSendLoading = true;
      state.otpSendErrorCode = 0;
      state.otpSendMessage = '';
    });
    // Send otp success state.
    builder.addCase(sendOtp.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isOtpSendLoading = false;
      state.isOtpSendSuccess = true;
      state.otpSendErrorCode = code;
      state.otpSendMessage = status || '';
    });
    // Send otp error state.
    builder.addCase(sendOtp.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isOtpSendSuccess = false;
      state.isOtpSendLoading = false;
      state.otpSendErrorCode = errcode;
      state.otpSendMessage = error || '';
    });
    // ********************* End of Send otp action state handling. *********************

    // ********************* Start of forgot password Send otp action state handling. *********************
    // Send forgot password otp default state.
    builder.addCase(forgotPassword.pending, (state) => {
      state.isForgotOtpSendSuccess = false;
      state.isForgotOtpSendLoading = true;
      state.forgotOtpSendErrorCode = 0;
      state.forgotOtpSendMessage = '';
    });
    // Send forgot password otp success state.
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isForgotOtpSendLoading = false;
      state.isForgotOtpSendSuccess = true;
      state.forgotOtp = '';
      state.forgotOtpSendErrorCode = code;
      state.forgotOtpSendMessage = status || '';
    });
    // Send forgot password otp error state.
    builder.addCase(forgotPassword.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isForgotOtpSendSuccess = false;
      state.isForgotOtpSendLoading = false;
      state.forgotOtpSendErrorCode = errcode;
      state.forgotOtpSendMessage = error || '';
    });
    // ********************* End of Send forgot password otp action state handling. *********************

    // ********************* Start of forgot password Resend otp action state handling. *********************
    // Resend forgot password otp default state.
    builder.addCase(forgotPasswordOtpResend.pending, (state) => {
      state.isForgotOtpResendSuccess = false;
      state.isForgotOtpResendLoading = true;
      state.forgotOtpResendErrorCode = 0;
      state.forgotOtpResendMessage = '';
    });
    // Resend forgot password otp success state.
    builder.addCase(forgotPasswordOtpResend.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isForgotOtpResendLoading = false;
      state.isForgotOtpResendSuccess = true;
      state.forgotResendOtp = '';
      state.forgotOtpResendErrorCode = code;
      state.forgotOtpResendMessage = status || '';
    });
    // Resend forgot password otp error state.
    builder.addCase(forgotPasswordOtpResend.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isForgotOtpResendSuccess = false;
      state.isForgotOtpResendLoading = false;
      state.forgotOtpResendErrorCode = errcode;
      state.forgotOtpResendMessage = error || '';
    });
    // ********************* End of Resend forgot password otp action state handling. *********************

    // ********************* Start of forgot password reset action state handling. *********************
    // Reset password default state.
    builder.addCase(resetPassword.pending, (state) => {
      state.isResetPasswordLoading = true;
      state.isResetPasswordSuccess = false;
      state.resetPasswordErrorCode = 0;
      state.resetPasswordMessage = '';
    });
    // Reset password success state.
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isResetPasswordLoading = false;
      state.isResetPasswordSuccess = true;
      state.resetPasswordErrorCode = code;
      state.resetPasswordMessage = status || '';
    });
    // Reset password error state.
    builder.addCase(resetPassword.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isResetPasswordLoading = false;
      state.isResetPasswordSuccess = false;
      state.resetPasswordErrorCode = errcode;
      state.resetPasswordMessage = error || '';
    });
    // ********************* End of forgot password reset action state handling. *********************

    // ********************* Start of change password action state handling. *********************
    // Change password default state.
    builder.addCase(changePassword.pending, (state) => {
      state.isChangePasswordLoading = true;
      state.isChangePasswordSuccess = false;
      state.changePasswordErrorCode = 0;
      state.changePasswordMessage = '';
    });
    // Change password success state.
    builder.addCase(changePassword.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isChangePasswordLoading = false;
      state.isChangePasswordSuccess = true;
      state.changePasswordErrorCode = code;
      state.changePasswordMessage = status || '';
    });
    // Change password error state.
    builder.addCase(changePassword.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isChangePasswordLoading = false;
      state.isChangePasswordSuccess = false;
      state.changePasswordErrorCode = errcode;
      state.changePasswordMessage = error || '';
    });
    // ********************* End of change password action state handling. *********************

    // ********************* Start of fetch schedules and leave details action state handling. *********************
    // Get schedules and leave details default state.
    builder.addCase(getSchedulesAndLeaveDetails.pending, (state) => {
      state.isUserScheduleAndLeaveDetailsSuccess = true;
      state.isUserScheduleAndLeaveDetailsLoading = false;
      state.userScheduleAndLeaveDetailsErrorCode = 0;
      state.userScheduleAndLeaveDetailsMessage = '';
    });
    //  Get schedules and leave details success state.
    builder.addCase(getSchedulesAndLeaveDetails.fulfilled, (state, action) => {
      const { code, status, info } = action.payload;
      state.isUserScheduleAndLeaveDetailsLoading = false;
      state.isUserScheduleAndLeaveDetailsSuccess = true;
      state.userScheduleAndLeaveDetails = info;
      state.userScheduleAndLeaveDetailsErrorCode = code;
      state.userScheduleAndLeaveDetailsMessage = status || '';
    });
    //  Get schedules and leave details error state.
    builder.addCase(getSchedulesAndLeaveDetails.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isUserScheduleAndLeaveDetailsLoading = false;
      state.isUserScheduleAndLeaveDetailsSuccess = false;
      state.userScheduleAndLeaveDetailsErrorCode = errcode;
      state.userScheduleAndLeaveDetailsMessage = error || '';
    });
    // ********************* End of fetch schedules and leave details action state handling. *********************

    // ********************* Start of shift holiday checking action state handling. *********************
    // Shift holiday checking default state.
    builder.addCase(isShiftDateISHoliday.pending, (state) => {
      state.isShiftHolidaySuccess = true;
      state.isShiftHolidayLoading = false;
      state.shiftHolidayErrorCode = 0;
      state.shiftHolidayMessage = '';
    });
    //  Shift holiday checking success state.
    builder.addCase(isShiftDateISHoliday.fulfilled, (state, action) => {
      const { code, status, info } = action.payload;
      state.isShiftHolidayLoading = false;
      state.isShiftHolidaySuccess = true;
      state.isHoliday = info;
      state.shiftHolidayErrorCode = code;
      state.shiftHolidayMessage = status || '';
    });
    //  Shift holiday checking error state.
    builder.addCase(isShiftDateISHoliday.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isShiftHolidayLoading = false;
      state.isShiftHolidaySuccess = false;
      state.shiftHolidayErrorCode = errcode;
      state.shiftHolidayMessage = error || '';
    });
    // ********************* End of shift holiday checking action state handling. *********************

    // ********************* Start of fetching callout reasons action state handling. *********************
    // Get all callout reasons default state.
    builder.addCase(getCalloutReasons.pending, (state) => {
      state.isFetchCalloutReasonSuccess = false;
      state.isFetchCalloutReasonLoading = true;
      state.fetchCalloutReasonErrorCode = 0;
      state.fetchCalloutReasonMessage = '';
    });
    //  Get all callout reasons success state.
    builder.addCase(getCalloutReasons.fulfilled, (state, action) => {
      const { code, status, info } = action.payload;
      state.isFetchCalloutReasonLoading = false;
      state.isFetchCalloutReasonSuccess = true;
      state.fetchCalloutReasonErrorCode = code;
      state.fetchCalloutReasonMessage = status || '';
      state.callOutReasons = info;
    });
    //  Get all callout reasons error state.
    builder.addCase(getCalloutReasons.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isFetchCalloutReasonSuccess = false;
      state.isFetchCalloutReasonLoading = false;
      state.fetchCalloutReasonErrorCode = errcode;
      state.fetchCalloutReasonMessage = error || '';
    });
    // ********************* End of fetching callout reasons action state handling. *********************

    // ********************* Clear all common states to initial sate action. *********************
    builder.addCase(revertAllCommonState, (state) => {
      state.isVerifyOtpSuccess = false;
      state.isVerifyOtpLoading = false;
      state.verifyOtpErrorCode = 0;
      state.verifyOtpMessage = null;
      state.verifyOtpResponse = null;
      state.isOtpSendLoading = false;
      state.isOtpSendSuccess = false;
      state.otpSendErrorCode = 0;
      state.otpSendMessage = null;
      state.isForgotOtpSendSuccess = false;
      state.isForgotOtpSendLoading = false;
      state.forgotOtpSendErrorCode = 0;
      state.forgotOtpSendMessage = null;
      state.forgotOtp = '';
      state.isForgotOtpResendSuccess = false;
      state.isForgotOtpResendLoading = false;
      state.forgotOtpResendErrorCode = 0;
      state.forgotOtpResendMessage = null;
      state.forgotResendOtp = '';
      state.isResetPasswordLoading = false;
      state.isResetPasswordSuccess = false;
      state.resetPasswordErrorCode = 0;
      state.resetPasswordMessage = null;
      state.isChangePasswordLoading = false;
      state.isChangePasswordSuccess = false;
      state.changePasswordErrorCode = 0;
      state.changePasswordMessage = null;
      state.isUserScheduleAndLeaveDetailsSuccess = false;
      state.isUserScheduleAndLeaveDetailsLoading = false;
      state.userScheduleAndLeaveDetailsErrorCode = 0;
      state.userScheduleAndLeaveDetailsMessage = null;
      state.userScheduleAndLeaveDetails = null;
      state.isShiftHolidayLoading = false;
      state.isShiftHolidaySuccess = false;
      state.shiftHolidayErrorCode = 0;
      state.shiftHolidayMessage = null;
      state.isFetchCalloutReasonLoading = false;
      state.isFetchCalloutReasonSuccess = false;
      state.fetchCalloutReasonErrorCode = 0;
      state.fetchCalloutReasonMessage = null;
    });
  },
});
// Export common actions.
export const { resetCommonState } = commonSlice.actions;
// Export reducer.
export default commonSlice.reducer;
