/**
 * @file   src\components\AdminMenu.tsx
 * @brief  This component used to create Admin menu.
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { MenuItem, useNavigate, useLocation, useEffect, useState, moment } from './ThirdPartyComponents';
import ManageCAIcon from '../assests/icons/ManageCA';
import ManageScheduleIcon from '../assests/icons/ManageSchedule';
import ManageCalloutIcon from '../assests/icons/ManageCallout';
import ManageSwapIcon from '../assests/icons/ManageSwap';
import ManageLeaveIcon from '../assests/icons/ManageLeave';
import ManageHolidaysIcon from '../assests/icons/ManageHolidays';
import TrackLDReasonsIcon from '../assests/icons/TrackLD';
import ReportIcon from '../assests/icons/Reports';
import { AdminRoutePath, ManagerRoutePath } from '../utils/enums';
import Strings from '../assests/strings/Strings.json';
import { buildQueryString, checkPathMatched, getDataFromStorage } from '../utils/helper';
import { IMenuItemProp } from '../interfaces/generalInterface';
import { CURRENT_DATE_FORMAT_WITH_OFFSET, KKI_ATKN, NUMBER_0, NUMBER_1, NUMBER_3, STORAGE_MANAGE_SCHEDULE_FILTER, STORAGE_VIEW_SCHEDULE_FILTER } from '../utils/constants';
import { ManageCallOutShiftApi } from '../utils/apiUrls';
import { useAppDispatch } from '../hooks';
import { updateNotificationCount } from '../store/slices/manageCallOutShiftSlice';

const AdminMenu = ({ toggleMenu }: IMenuItemProp) => {
  // Navigate object creation.
  const navigate = useNavigate();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  /* Create location object to access location info. */
  const location = useLocation();

  // Initialize component state variables.
  const [callOutCount, setCallOutCount] = useState<number>(NUMBER_0);

  // Component initial loading.
  useEffect(() => {
    if (
      !checkPathMatched(
        [
          AdminRoutePath.ManageSchedule,
          AdminRoutePath.SCHShiftDetails,
          AdminRoutePath.SCHAssignCA,
          AdminRoutePath.SCHViewSchedules,
          AdminRoutePath.SCHCASchedules,
          AdminRoutePath.SCHShiftDetailsByCAId,
          AdminRoutePath.SCHAssignCAByCAId,
        ],
        location?.pathname,
      )
    ) {
      // Remove the manage schedule filter session if the current route is outside the manage schedules menu (parent or child menus)
      localStorage.removeItem(STORAGE_MANAGE_SCHEDULE_FILTER);
      localStorage.removeItem(STORAGE_VIEW_SCHEDULE_FILTER);
    }

    const apiKey = process.env.REACT_APP_API_API_KEY;
    const tokenInfo = getDataFromStorage(KKI_ATKN, true);
    const accessToken = tokenInfo?.access_token;

    const sseRequest = {
      apikey: apiKey,
      token: accessToken,
      currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET),
    };

    const maxReconnects = NUMBER_3;
    let reconnectAttempts = NUMBER_0;
    // opening a connection to the server to begin receiving events from it
    const eventSource = new EventSource(`${process.env.REACT_APP_API_URL}${ManageCallOutShiftApi.CALLOUT_SHIFT_COUNT}?${buildQueryString(sseRequest)}`);

    // attaching a handler to receive message events
    eventSource.onmessage = (event) => {
      const callOuts = event?.data?.split(' ')[1] || NUMBER_0;
      setCallOutCount(callOuts);
    };

    eventSource.onerror = (err) => {
      if (reconnectAttempts > maxReconnects) {
        eventSource?.close();
      } else {
        reconnectAttempts += NUMBER_1;
      }
      // eslint-disable-next-line no-console
      console.log(err);
    };
    // terminating the connection on component unmount
    return () => eventSource?.close();
  }, [location?.pathname]);

  useEffect(() => {
    dispatch(updateNotificationCount(callOutCount));
  }, [callOutCount]);

  // Menu navigation event.
  const navigateUrl = (url: string) => {
    navigate(url);
    toggleMenu(false);
  };

  return (
    <>
      <MenuItem
        icon={<ManageCAIcon />}
        onClick={() => navigateUrl(ManagerRoutePath.MNGRUserList)}
        active={checkPathMatched([ManagerRoutePath.MNGRUserList, AdminRoutePath.SCHCADetails, ManagerRoutePath.MNGRUserDetails], location?.pathname)}
      >
        {Strings.Menu.ManageUsers}
      </MenuItem>

      <MenuItem
        active={checkPathMatched(
          [
            AdminRoutePath.ManageSchedule,
            AdminRoutePath.SCHShiftDetails,
            AdminRoutePath.SCHAssignCA,
            AdminRoutePath.SCHViewSchedules,
            AdminRoutePath.SCHCASchedules,
            AdminRoutePath.SCHShiftDetailsByCAId,
            AdminRoutePath.SCHAssignCAByCAId,
          ],
          location?.pathname,
        )}
        icon={<ManageScheduleIcon />}
        onClick={() => navigateUrl(AdminRoutePath.ManageSchedule)}
      >
        {Strings.Menu.ManageSchedules}
      </MenuItem>
      <MenuItem
        icon={<ManageCalloutIcon />}
        active={checkPathMatched([AdminRoutePath.SCHCallOut, AdminRoutePath.SCHCAResource], location?.pathname)}
        onClick={() => navigateUrl(AdminRoutePath.SCHCallOut)}
      >
        {Strings.Menu.CallOutShifts}
        {callOutCount > NUMBER_0 && <span className="callout-count">{callOutCount}</span>}
      </MenuItem>

      <MenuItem
        icon={<TrackLDReasonsIcon />}
        onClick={() => navigateUrl(ManagerRoutePath.TrackLDReason)}
        active={checkPathMatched([ManagerRoutePath.TrackLDReason], location?.pathname)}
      >
        {Strings.Menu.TrackLdReasons}
      </MenuItem>

      <MenuItem icon={<ManageSwapIcon />} active={checkPathMatched([AdminRoutePath.SCHManageSwap], location?.pathname)} onClick={() => navigateUrl(AdminRoutePath.SCHManageSwap)}>
        {Strings.Menu.ManageSwaps}
      </MenuItem>
      <MenuItem
        icon={<ManageLeaveIcon />}
        active={checkPathMatched([AdminRoutePath.SCHManageLeave], location?.pathname)}
        onClick={() => navigateUrl(AdminRoutePath.SCHManageLeave)}
      >
        {Strings.Menu.ManageLeave}
      </MenuItem>
      <MenuItem
        icon={<ManageHolidaysIcon />}
        active={checkPathMatched([AdminRoutePath.SCHManageHolidays], location?.pathname)}
        onClick={() => navigateUrl(AdminRoutePath.SCHManageHolidays)}
      >
        {Strings.Menu.ManageHolidays}
      </MenuItem>

      <MenuItem icon={<ReportIcon />} onClick={() => navigateUrl(ManagerRoutePath.MNGRReport)} active={checkPathMatched([ManagerRoutePath.MNGRReport], location?.pathname)}>
        {Strings.Menu.Report}
      </MenuItem>
    </>
  );
};

export default AdminMenu;
