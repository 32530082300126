/**
 * @file   src\components\KKIInput.ts
 * @brief  Reusable input component.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { React, Form } from './ThirdPartyComponents';
import '../styles/KKIInput.scss';
import { KKIInputPrp } from '../interfaces/generalInterface';
import VerifyIcon from '../assests/images/verify-icon.svg';

const KKIInput = ({ id, name, type, placeholder, onChange, value, onKeyDown, alert, showVerifyIcon, label, disabled, rows, as, maxLength }: KKIInputPrp) => {
  return (
    <Form.Group className={showVerifyIcon ? 'kki-input position-relative' : 'kki-input'}>
      <label htmlFor="label name">{label}</label>
      <Form.Control
        id={id}
        className={alert && alert !== '' ? 'form-control error' : value !== '' ? 'form-control' : 'form-control'}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        autoComplete="off"
        onKeyDown={onKeyDown}
        disabled={disabled}
        as={as}
        rows={rows}
        maxLength={maxLength}
      />
      {showVerifyIcon ? (
        <div className="verify-wrap">
          Verified
          <img src={VerifyIcon} alt="Verify Icon" />
        </div>
      ) : (
        ''
      )}

      {alert && alert !== '' && <span className="error">{alert}</span>}
    </Form.Group>
  );
};
export default KKIInput;
