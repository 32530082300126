/**
 * @file   src\components\LeaveDetailsCard.tsx
 * @brief  Component for Leave details popup.
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React from 'react';
import Strings from '../assests/strings/Strings.json';
import '../styles/ShiftCard.scss';
import { Col, Row, moment } from './ThirdPartyComponents';
import { ILeaveCardProps } from '../interfaces/leaveInterface';
import Approved from '../assests/icons/Publish';
import Requested from '../assests/icons/Requested';
import { LeaveStatus, LeaveTypes } from '../utils/enums';
import { DATE_FORMAT_MM_DD_YYYY } from '../utils/constants';

const LeaveDetailsCard = ({ data }: ILeaveCardProps) => {
  return (
    <Row className="details-view">
      {data ? (
        <>
          <Col xl={4} md={6} sm={6} xs={12} className="mb-4">
            {Strings.Label.LeaveType}:<span>{LeaveTypes[data.leaveType].replace(/_/g, ' ')}</span>
          </Col>
          <Col xl={4} md={6} sm={6} xs={6} className="mb-4">
            {Strings.Label.LeaveFrom}:<span>{moment(data.leaveFrom).format(DATE_FORMAT_MM_DD_YYYY)}</span>
          </Col>
          <Col xl={4} md={6} sm={6} xs={6} className="mb-4">
            {Strings.Label.LeaveTo}:<span>{moment(data.leaveTo).format(DATE_FORMAT_MM_DD_YYYY)}</span>
          </Col>
          <Col xl={4} md={6} sm={6} xs={12} className="mb-4">
            {Strings.Label.LeaveDays}:<span>{data.noOfLeaves}</span>
          </Col>
          <Col xl={8} md={12} sm={12} xs={12} className="mb-4">
            {Strings.Leave.Reason}:<span>{data.reason}</span>
          </Col>
          <Col xl={12} className="mb-4">
            {Strings.Label.Status}:
            {data.leaveStatus === LeaveStatus.Pending_Approval ? (
              <span>
                {Strings.Leave.Requested} <Requested />
              </span>
            ) : (
              ''
            )}
            {data.leaveStatus === LeaveStatus.Approved ? (
              <span>
                {Strings.Leave.Approved} <Approved />
              </span>
            ) : (
              ''
            )}
          </Col>
        </>
      ) : (
        Strings.Text.NoDataFound
      )}
    </Row>
  );
};
export default LeaveDetailsCard;
