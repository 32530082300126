/**
 * @file   src\containers\Manager\ManageUser\UserList.tsx
 * @brief  User listing page
 * @date   October, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Row, Col, Table, Button, Form, useState, useNavigate, useLocation, useEffect, Modal } from '../../../components/ThirdPartyComponents';
import KKISearch from '../../../components/KKISearch';
import Strings from '../../../assests/strings/Strings.json';
import { NUMBER_1, USER_NAME, DESC, ASC, HTTP_STATUS_200, MOT_POSITION, ROLE, MANAGER, NUMBER_50, STORAGE_USER } from '../../../utils/constants';
import { activateOrDeactivateCA, revertAllManageCAState } from '../../../store/actions/manageCAAction';
import { IUserProfile } from '../../../interfaces/userInterface';
import { RootState } from '../../../store';
import { MessageToaster } from '../../../utils/toastUtils';
import Pagination from '../../../components/Paginate';
import Sort from '../../../assests/icons/Sort';
import SortUp from '../../../assests/icons/SortUp';
import SortDown from '../../../assests/icons/SortDown';
import Loader from '../../../components/Loader';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getAllUsers, revertAllUserState, activateOrDeactivateScheduler } from '../../../store/actions/userAction';
import { AdminRoutePath, ManagerRoutePath, ColorGroupNames, ColorGroups, RoleTypes, RoleTypeNames } from '../../../utils/enums';
import { formatPhoneNumber, getDataFromStorage, stringFormat } from '../../../utils/helper';

// Toast object creation.
const toast = new MessageToaster();

const MNGRUserList = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();
  // Navigate object creation.
  const navigate = useNavigate();
  // Create location object to access location info.
  const location = useLocation();

  // Access userInfo from the storage.
  const userData = getDataFromStorage(STORAGE_USER);
  // Loggined user role
  const userId = userData?.userId;

  // Access redux state variables.
  const { userInfo, total, isFetchUsersLoading, limit } = useAppSelector((state: RootState) => state.user);
  const { isUpdateCAStatusSuccess, updateCAStatusErrorCode, updateCAStatusMessage } = useAppSelector((state: RootState) => state.manageCA);
  const { isUpdateSchedulerStatusSuccess, updateSchedulerStatusErrorCode, updateSchedulerStatusMessage } = useAppSelector((state: RootState) => state.user);

  // Initialize component state variables.
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sortField, setSortField] = useState<string>(USER_NAME);
  const [sortOrder, setSortOrder] = useState<string>(DESC);
  const [statusChangePopup, showStatusPopup] = useState<boolean>(false);
  const [selectedCA, setSelectedCA] = useState<IUserProfile | null>(null);
  const [modalActionText, setModalActionText] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(NUMBER_1);

  // Component initial loading.
  useEffect(() => {
    if (location.state) {
      window.history.replaceState({}, '');
    }
    return () => {
      dispatch(revertAllUserState());
      dispatch(revertAllManageCAState());
    };
  }, []);

  // Fetch all users whenever dependencies change
  useEffect(() => {
    dispatch(getAllUsers({ keyword: searchQuery, page: currentPage, sort: sortField, order: sortOrder }));
  }, [dispatch, searchQuery, currentPage, sortField, sortOrder]);

  // Restore state when coming back from the detail page
  useEffect(() => {
    if (location.state) {
      const { fromPage, searchQuery: statesearchQuery, sortField: statesortField, sortOrder: stateSortOrder } = location.state;
      setCurrentPage(fromPage || 1);
      setSearchQuery(statesearchQuery || '');
      setSortField(statesortField || USER_NAME);
      setSortOrder(stateSortOrder || DESC);
    }
  }, [location.state]);
  // Update CA status api response state side effect handling.
  useEffect(() => {
    if (isUpdateCAStatusSuccess && updateCAStatusErrorCode === HTTP_STATUS_200 && updateCAStatusMessage) {
      dispatch(getAllUsers({ keyword: searchQuery, page: currentPage, sort: sortField, order: sortOrder }));
      toast.toastSuccess(updateCAStatusMessage);
    } else if (!isUpdateCAStatusSuccess && updateCAStatusErrorCode > HTTP_STATUS_200 && updateCAStatusMessage) {
      toast.toastError(updateCAStatusMessage);
    }
  }, [isUpdateCAStatusSuccess, updateCAStatusMessage, updateCAStatusErrorCode]);

  // Update scheduler status api response state side effect handling.
  useEffect(() => {
    if (isUpdateSchedulerStatusSuccess && updateSchedulerStatusErrorCode === HTTP_STATUS_200 && updateSchedulerStatusMessage) {
      dispatch(getAllUsers({ keyword: searchQuery, page: currentPage, sort: sortField, order: sortOrder }));
      toast.toastSuccess(updateSchedulerStatusMessage);
    } else if (!isUpdateSchedulerStatusSuccess && updateSchedulerStatusErrorCode > HTTP_STATUS_200 && updateSchedulerStatusMessage) {
      toast.toastError(updateSchedulerStatusMessage);
    }
  }, [isUpdateSchedulerStatusSuccess, updateSchedulerStatusMessage, updateSchedulerStatusErrorCode]);

  // Handle pagination
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };
  // Handle search action
  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(NUMBER_1);
  };

  // Handle sort action
  const handleSort = (field: string) => {
    if (sortField === field) {
      setSortOrder(sortOrder === ASC ? DESC : ASC);
    } else {
      setSortField(field);
      setSortOrder(ASC);
    }
  };
  // Calculate range of currently displayed items
  const startItem = (currentPage - NUMBER_1) * limit + NUMBER_1;
  const endItem = Math.min(currentPage * limit, total);

  // Toggles the status of the users (Active/Inactive) when the switch is checked/unchecked.
  const handleStatusToggle = (ca: IUserProfile) => {
    setSelectedCA(ca);
    setModalActionText(ca.status ? Strings.Text.UserDeactivateText : Strings.Text.UserActivateText);
    showStatusPopup(true);
  };
  // Handle modal confirmation
  const handleConfirmStatusChange = () => {
    if (selectedCA) {
      const newStatus = !selectedCA.status;
      if (selectedCA.role === RoleTypes.CA) {
        dispatch(activateOrDeactivateCA({ caId: selectedCA.userId, status: newStatus }));
      } else if (selectedCA.role === RoleTypes.Scheduler) {
        dispatch(activateOrDeactivateScheduler({ userId: selectedCA.userId, status: newStatus }));
      }
      showStatusPopup(false);
    }
  };
  return (
    <div>
      <div className="pageheader">
        <Row className="align-items-center justify-content-between">
          <Col>
            <h1>{Strings.HD.ManageUsers}</h1>
          </Col>
          <Col xl={3} lg={4} sm={6} xs={12} className="m-xs-16">
            <KKISearch placeholder={Strings.Input.SearchbyNameEmployeeID} onSearch={handleSearch} maxLength={NUMBER_50} value={searchQuery} />
          </Col>
        </Row>
      </div>
      <div>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th onClick={() => handleSort(USER_NAME)} style={{ cursor: 'pointer' }}>
                {Strings.Label.Name}
                <span className="ms-1">
                  {sortField !== USER_NAME && <Sort />}
                  {sortField === USER_NAME && sortOrder === DESC && <SortDown />}
                  {sortField === USER_NAME && sortOrder === ASC && <SortUp />}
                </span>
              </th>
              <th>{Strings.Label.EmpId}</th>
              <th>{Strings.Label.Contact}</th>
              <th>{Strings.Label.Email}</th>
              <th>{Strings.Label.ColorGroup}</th>
              <th onClick={() => handleSort(ROLE)} style={{ cursor: 'pointer' }}>
                {Strings.Label.Role}
                <span className="ms-1">
                  {sortField !== ROLE && <Sort />}
                  {sortField === ROLE && sortOrder === DESC && <SortDown />}
                  {sortField === ROLE && sortOrder === ASC && <SortUp />}
                </span>
              </th>
              <th onClick={() => handleSort(MOT_POSITION)} style={{ cursor: 'pointer' }}>
                {Strings.Label.MOTPosition}
                <span className="ms-1">
                  {sortField !== MOT_POSITION && <Sort />}
                  {sortField === MOT_POSITION && sortOrder === DESC && <SortDown />}
                  {sortField === MOT_POSITION && sortOrder === ASC && <SortUp />}
                </span>
              </th>
              <th>{Strings.Label.Status}</th>
              <th className="w-160">{Strings.Label.Action}</th>
            </tr>
          </thead>
          <tbody>
            {userInfo && userInfo.length > 0 ? (
              userInfo.map((ca: IUserProfile, index: number) => (
                <tr key={ca.userId}>
                  <td>
                    {ca.role === 3 ? (
                      <a
                        href={void 0}
                        onClick={() =>
                          navigate(`${AdminRoutePath.SCHCADetails.replace(':caId', ca.userId.toString())}?type=2`, {
                            state: {
                              source: MANAGER,
                              fromPage: currentPage,
                              searchQuery,
                              sortField,
                              sortOrder,
                            },
                          })
                        }
                      >
                        {ca.firstName && ca.lastName ? `${ca.firstName} ${ca.lastName}` : ''}
                      </a>
                    ) : (
                      <a
                        href={void 0}
                        onClick={() =>
                          navigate(`${ManagerRoutePath.MNGRUserDetails.replace(':schedulerId', ca.userId.toString())}`, {
                            state: {
                              fromPage: currentPage,
                              searchQuery,
                              sortField,
                              sortOrder,
                            },
                          })
                        }
                      >
                        {ca.firstName && ca.lastName ? `${ca.firstName} ${ca.lastName}` : ''}
                      </a>
                    )}
                  </td>
                  <td>{ca.empId}</td>
                  <td>{formatPhoneNumber(ca?.phone || '')}</td>
                  <td>{ca.email}</td>
                  <td>{ColorGroupNames[ca.colorGroup as ColorGroups]}</td>
                  <td>{RoleTypeNames[ca.role as RoleTypes]}</td>
                  <td>{ca.motPosition}</td>
                  <td>
                    <Form.Check
                      type="switch"
                      id={`status-switch-${index}`}
                      label={ca.status ? Strings.Label.Active : Strings.Label.Inactive}
                      checked={ca.status}
                      disabled={ca.userId === userId}
                      {...(ca.userId !== userId && { onChange: () => handleStatusToggle(ca) })}
                    />
                  </td>
                  <td>
                    {ca.role === 3 && (
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => navigate({ pathname: `${AdminRoutePath.SCHViewSchedules}/${ca.userId}` }, { state: { prevUrl: location.pathname } })}
                      >
                        {Strings.Button.ViewSchedules}
                      </Button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="text-center">
                  {Strings.Text.NoDataFound}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {isFetchUsersLoading && <Loader />}
      <Modal show={statusChangePopup} onHide={() => showStatusPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>
            {selectedCA && (
              <>
                {modalActionText} {selectedCA.firstName} {selectedCA.lastName}?
              </>
            )}
          </p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showStatusPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={handleConfirmStatusChange} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <div className="d-flex justify-content-between mt-4">
        <span>{total > 0 ? stringFormat(Strings.Text.ShowingAllUsers, startItem, endItem, total) : ''}</span>
        {total > limit && (
          //  <Pagination totalCount={total} pageLimit={pageSize} setCurrentPage={handlePageChange} currentPage={currentPage - 1} prevPage={-1} />
          <Pagination totalCount={total} pageLimit={limit} setCurrentPage={handlePageChange} currentPage={currentPage - 1} prevPage={-1} />
        )}
      </div>
    </div>
  );
};
export default MNGRUserList;
