/**
 * @file   src\components\commonComponents\ViewScheduleFilter.tsx
 * @brief  View schedules page
 * @date   Sep, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Button, Col, Filter, useState } from '../ThirdPartyComponents';
import KKICheckbox from '../KKICheckbox';
import Close from '../../assests/icons/Close';
import Strings from '../../assests/strings/Strings.json';
import { IKeyIndicators } from '../../interfaces/caInterface';
import { ShiftStatus } from '../../utils/enums';
import { IViewScheduleFilterProps } from '../../interfaces/scheduleInterface';

const ViewScheduleFilter = ({ selectedPublishValues, selectedIndicators, onChange }: IViewScheduleFilterProps) => {
  // Initialize component state variables.
  const [isFullScheduleFilterOpen, setIsFullScheduleFilterOpen] = useState<boolean>(false);
  const [selectedPublishStatus, setSelectedPublishStatus] = useState<number[]>(selectedPublishValues || [ShiftStatus.Published, ShiftStatus.Draft]);
  const [indicators, setIndicators] = useState<IKeyIndicators>(
    selectedIndicators || {
      floatStaff: true,
      student: true,
      volunteer: true,
      lightDuty: true,
      restrictedOT: true,
      partTime16: true,
      partTime20: true,
      partTime24: true,
      fullTime: true,
    },
  );

  // Handle publish status checkbox changes
  const handleStatusCheckboxChange = (status: number) => {
    setSelectedPublishStatus((prevStatuses) => (prevStatuses.includes(status) ? prevStatuses.filter((s) => s !== status) : [...prevStatuses, status]));
  };

  // Apply filters button submit event handler.
  const applyFilters = () => {
    onChange({
      publishStatus: selectedPublishStatus,
      selectedIndicators: indicators,
    });
    setIsFullScheduleFilterOpen(false);
  };

  // Key indicators check change handler.
  const handleKeyIndicatorCheckboxChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = evt.target;
    setIndicators((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  // Full schedule filter change handler
  const handleFullScheduleFilterChange = (state: { isOpen: boolean }) => {
    setIsFullScheduleFilterOpen(state.isOpen);
  };

  // Reset filters button submit event handler.
  const resetFilters = () => {
    setSelectedPublishStatus([ShiftStatus.Published, ShiftStatus.Draft]);
    setIndicators((prev) => ({
      ...prev,
      floatStaff: true,
      student: true,
      volunteer: true,
      lightDuty: true,
      restrictedOT: true,
      partTime16: true,
      partTimw20: true,
      partTime24: true,
      fullTime: true,
    }));
  };

  return (
    <Filter
      width={310}
      right
      pageWrapId="filter-wrapper"
      outerContainerId="outer-container"
      isOpen={isFullScheduleFilterOpen}
      onStateChange={handleFullScheduleFilterChange}
      customCrossIcon={<Close />}
      bodyClassName="overflow-hidden"
    >
      <div id="filter-wrapper" className="filter-overlay">
        <div className="overlay-hd p-3 border-bottom">
          <h1>{Strings.Filter.Filter}</h1>
        </div>
        <div className="overlay-filter-content">
          <div className="mb-4">
            <h3>{Strings.Filter.PublishStatus}</h3>
            <KKICheckbox
              id="p1"
              key={1}
              name="publishStatus"
              checkBoxType="checkbox"
              label={Strings.Filter.Publish}
              checked={selectedPublishStatus.includes(ShiftStatus.Published)}
              onChange={() => handleStatusCheckboxChange(ShiftStatus.Published)}
            />
            <KKICheckbox
              id="p2"
              key={2}
              name="publishStatus"
              checkBoxType="checkbox"
              label={Strings.Filter.Draft}
              checked={selectedPublishStatus.includes(ShiftStatus.Draft)}
              onChange={() => handleStatusCheckboxChange(ShiftStatus.Draft)}
            />
          </div>
          <div>
            <h3>{Strings.CAKeyIndicators.Header}</h3>
            <KKICheckbox
              id="k0"
              key={1}
              name="floatStaff"
              checkBoxType="checkbox"
              label={Strings.CAKeyIndicators.Float}
              checked={indicators.floatStaff}
              onChange={handleKeyIndicatorCheckboxChange}
            />
            <KKICheckbox
              id="k1"
              key={1}
              name="student"
              checkBoxType="checkbox"
              label={Strings.CAKeyIndicators.Student}
              checked={indicators.student}
              onChange={handleKeyIndicatorCheckboxChange}
            />
            <KKICheckbox
              id="k2"
              key={2}
              name="volunteer"
              checkBoxType="checkbox"
              label={Strings.CAKeyIndicators.Voluntary}
              checked={indicators.volunteer}
              onChange={handleKeyIndicatorCheckboxChange}
            />
            <KKICheckbox
              id="k3"
              key={3}
              name="lightDuty"
              checkBoxType="checkbox"
              label={Strings.CAKeyIndicators.LightDuty}
              checked={indicators.lightDuty}
              onChange={handleKeyIndicatorCheckboxChange}
            />
            <KKICheckbox
              id="k4"
              key={4}
              name="restrictedOT"
              checkBoxType="checkbox"
              label={Strings.CAKeyIndicators.RestrictedOT}
              checked={indicators.restrictedOT}
              onChange={handleKeyIndicatorCheckboxChange}
            />
            <KKICheckbox
              id="k5"
              key={5}
              name="partTime16"
              checkBoxType="checkbox"
              label={Strings.CAKeyIndicators.PartTime16hrs}
              checked={indicators.partTime16}
              onChange={handleKeyIndicatorCheckboxChange}
            />
            <KKICheckbox
              id="k6"
              key={6}
              name="partTime20"
              checkBoxType="checkbox"
              label={Strings.CAKeyIndicators.PartTime20hrs}
              checked={indicators.partTime20}
              onChange={handleKeyIndicatorCheckboxChange}
            />
            <KKICheckbox
              id="k7"
              key={7}
              name="partTime24"
              checkBoxType="checkbox"
              label={Strings.CAKeyIndicators.PartTime24hrs}
              checked={indicators.partTime24}
              onChange={handleKeyIndicatorCheckboxChange}
            />
            <KKICheckbox
              id="k8"
              key={8}
              name="fullTime"
              checkBoxType="checkbox"
              label={Strings.CAKeyIndicators.FullTime}
              checked={indicators.fullTime}
              onChange={handleKeyIndicatorCheckboxChange}
            />
          </div>
        </div>

        <Col className="overlay-button-container">
          <Button variant="outline-primary" onClick={resetFilters}>
            {Strings.Button.Reset}
          </Button>
          <Button variant="primary" onClick={applyFilters}>
            {Strings.Button.Apply}
          </Button>
        </Col>
      </div>
    </Filter>
  );
};

export default ViewScheduleFilter;
