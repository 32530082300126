/**
 * @file   src\containers\SetupProfile.tsx
 * @brief  SetupProfile page.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Col, Button, useNavigate, useState, useEffect } from '../components/ThirdPartyComponents';
import Strings from '../assests/strings/Strings.json';
import KKIInput from '../components/KKIInput';
import KKICheckbox from '../components/KKICheckbox';
import TopLogo from '../assests/images/logo.svg';
import { RoleTypes, RoutePath } from '../utils/enums';
import { IObject } from '../interfaces/generalInterface';
import { IProfileSetupRequest } from '../interfaces/userInterface';
import { validateForm } from '../utils/validationHelper';
import { PROFILE_SETUP_SCHEMA } from '../validations/userSchema';
import { useAppDispatch, useAppSelector } from '../hooks';
import { revertAllUserState, setUpAdminProfile, setUpProfile } from '../store/actions/userAction';
import { getDataFromStorage } from '../utils/helper';
import { HTTP_STATUS_200, NUMBER_150, NUMBER_50, STORAGE_USER } from '../utils/constants';
import { RootState } from '../store';
import { MessageToaster } from '../utils/toastUtils';

// Toast object creation.
const toast = new MessageToaster();

const CreateProfile = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Navigate object creation.
  const navigate = useNavigate();

  // Access storage values.
  const userInfo = getDataFromStorage(STORAGE_USER);
  const role = userInfo?.roleId;

  // Access redux state variables.
  const { isProfileSetupSuccess, profileSetupErrorCode, profileSetupMessage } = useAppSelector((state: RootState) => state.user);

  // Initialize component state variables.
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isVoluntary, setIsVoluntary] = useState<boolean>(false);
  const [errorFields, setErrorFields] = useState<IObject>({});

  // On clicking sign in from the profile setup page clear the session and redirect to login page
  const ClearSessionAndRedirect = () => {
    localStorage.removeItem(STORAGE_USER);
    navigate(RoutePath.Login);
  };

  // Update profile details api response state side effect handling.
  useEffect(() => {
    if (isProfileSetupSuccess && profileSetupErrorCode === HTTP_STATUS_200 && profileSetupMessage) {
      toast.toastSuccess(profileSetupMessage, 5000);
      ClearSessionAndRedirect(); // clear session and navigate to login page
    } else if (!isProfileSetupSuccess && profileSetupErrorCode > HTTP_STATUS_200 && profileSetupMessage) {
      toast.toastError(profileSetupMessage);
    }
  }, [isProfileSetupSuccess, profileSetupErrorCode, profileSetupMessage]);

  // Component unmound loading.
  useEffect(() => {
    return () => {
      dispatch(revertAllUserState());
    };
  }, []);

  // Input first name element change event handler.
  const onFirstNameInputChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const errorresult = await validateForm({ firstName: value }, PROFILE_SETUP_SCHEMA, errorFields);
    setErrorFields(errorresult);
    setFirstName(value);
  };

  // Input last name element change event handler.
  const onLastNameInputChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const errorresult = await validateForm({ lastName: value }, PROFILE_SETUP_SCHEMA, errorFields);
    setErrorFields(errorresult);
    setLastName(value);
  };

  // Input email element change event handler.
  const onEmailInputChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const errorresult = await validateForm({ email: value }, PROFILE_SETUP_SCHEMA, errorFields);
    setErrorFields(errorresult);
    setEmail(value);
  };

  // IsVoluntary checkbox change event handler.
  const onIsVoluntaryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIsVoluntary(checked);
  };

  // Profile setup form submit button event handler.
  const onSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const profileSetupForm: IProfileSetupRequest = {
      ...(Number(role) === RoleTypes.CA && { userId: userInfo?.userId || 0, isVolunteer: isVoluntary }), // Only include userId, isVolunteer if the role is CA
      firstName,
      lastName,
      email,
    };

    const errorResult = await validateForm(profileSetupForm, PROFILE_SETUP_SCHEMA, errorFields);
    setErrorFields(errorResult);

    if (Object.keys(errorResult).length === 0) {
      if (Number(role) === RoleTypes.CA) {
        dispatch(setUpProfile(profileSetupForm));
      } else {
        dispatch(setUpAdminProfile(profileSetupForm));
      }
    }
  };

  return (
    <Col className="cs-form-sec" xl={8} lg={8} md={10} sm={11} xs={10}>
      <div className="kki-logo-wrap">
        <img src={TopLogo} alt="kki logo" />
      </div>
      <h3 className="text-center">{Strings.SetupProfile.SetProfile}</h3>
      <form name="profileSetupForm" onSubmit={onSubmit}>
        <KKIInput
          id="firstname"
          name="firstName"
          type="text"
          placeholder={Strings.SetupProfile.FirstName}
          onChange={onFirstNameInputChange}
          alert={errorFields?.firstName || ''}
          maxLength={NUMBER_50}
        />
        <KKIInput
          id="lastname"
          name="lastName"
          type="text"
          placeholder={Strings.SetupProfile.LastName}
          onChange={onLastNameInputChange}
          alert={errorFields?.lastName || ''}
          maxLength={NUMBER_50}
        />
        <KKIInput
          id="email"
          name="email"
          type="email"
          placeholder={Strings.SetupProfile.Email}
          onChange={onEmailInputChange}
          alert={errorFields?.email || ''}
          maxLength={NUMBER_150}
        />
        {Number(role) === RoleTypes.CA && <h6>{Strings.SetupProfile.SmallHeading}</h6>}
        {Number(role) === RoleTypes.CA && (
          <div className="d-flex align-items-center justify-content-between mb-4">
            <KKICheckbox id="kkicheckid" name="isVoluntary" checkBoxType="checkbox" label={Strings.SetupProfile.CheckTxt} checked={isVoluntary} onChange={onIsVoluntaryChange} />
          </div>
        )}

        <div className="">
          <Button variant="primary" type="submit" className="mb-30 w-100">
            {Strings.SetupProfile.SubmitBtnTxt}
          </Button>
        </div>
      </form>
    </Col>
  );
};

export default CreateProfile;
