/**
 * @file   src\utils\toastUtils.ts
 * @brief  This file is responsible for configuring toast message popup.
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { toast } from '../components/ThirdPartyComponents';

export class MessageToaster {
  /**
   * Provides methods to toast error and success messages.
   * uses react-toastify package
   * toastProperties : determines where the toast appears on the screen and other properties
   */
  toastProperties: any;

  constructor() {
    this.toastProperties = {
      position: 'top-right',
      autoClose: 8000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    };
  }

  toastError(message: string) {
    toast.error(message, this.toastProperties);
  }

  toastSuccess(message: string, delay?: number) {
    const toastPropertiesWithDelay = {
      ...this.toastProperties,
      autoClose: delay ?? this.toastProperties.autoClose,
    };
    toast.success(message, toastPropertiesWithDelay);
  }
}
