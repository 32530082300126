/**
 * @file   src\store\actions\manageAdminScheduleAction.ts
 * @brief  This file is responsible for creating asynchronous api call for managing Admin(scheduler and manager) schedule slice.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  IAddNewShiftRequest,
  IDeleteShift,
  IGetAllShiftsRequest,
  IGetCAShiftsAndLeaveDetailsRequest,
  IPublishShift,
  IAssignedOrUnassigedCAListRequest,
  IUpdateNoshowAndOncallRequest,
} from '../../interfaces/scheduleInterface';
import { KKIActionTypes, ManageAdminScheduleApi } from '../../utils/apiUrls';
import axios from '../../utils/apiConfig';
import { buildQueryString } from '../../utils/helper';
import { QUERY_STRING_ARRAY_FORMAT } from '../../utils/constants';

// Add new shift api call.
export const addNewShift = createAsyncThunk('/manageAdminSchedule/addNewShift', async (request: IAddNewShiftRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageAdminScheduleApi.ADD_NEW_SHIFT, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get all shift with summary api call.
export const getAllShiftsWithSummary = createAsyncThunk('/manageAdminSchedule/getAllShiftsWithSummary', async (request: IGetAllShiftsRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${ManageAdminScheduleApi.GET_ALL_SHIFTS}?${buildQueryString(request, QUERY_STRING_ARRAY_FORMAT)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get assigned or unassigned ca list api call.
export const getAssignedOrUnassignedCAList = createAsyncThunk(
  '/manageAdminSchedule/getAssignedOrUnassignedCAList',
  async (request: IAssignedOrUnassigedCAListRequest, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${ManageAdminScheduleApi.GET_ASSIGNED_OR_UNASSIGEND_CA_LIST}?${buildQueryString(request, QUERY_STRING_ARRAY_FORMAT)}`);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

// Publish shift api call.
export const publishShift = createAsyncThunk('/manageAdminSchedule/publishShift', async (request: IPublishShift, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageAdminScheduleApi.PUBLISH_SHIFT, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Delete shift api call.
export const deleteShift = createAsyncThunk('/manageAdminSchedule/deleteShift', async (request: IDeleteShift, { rejectWithValue }) => {
  try {
    const { data } = await axios.delete(`${ManageAdminScheduleApi.DELETE_SHIFT}/${request?.shiftId}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

//  Get all CA/given CA with their schedule and leave by filter api call.
export const getAllCAScheduleAndLeaveDetails = createAsyncThunk(
  '/manageAdminSchedule/getAllCAScheduleAndLeaveDetails',
  async (request: IGetCAShiftsAndLeaveDetailsRequest, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${ManageAdminScheduleApi.GET_ALL_CA_WITH_THEIR_SHEDULE_AND_LEAVE_DETAILS}?${buildQueryString(request)}`);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

// Add recurring shift shift api call.
export const addRecurringShift = createAsyncThunk('/manageAdminSchedule/addRecurringShift', async (request: IAddNewShiftRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageAdminScheduleApi.ADD_RECURRING_SHIFT, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Edit shift api call.
export const editShift = createAsyncThunk('/manageAdminSchedule/editShift', async (request: IAddNewShiftRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageAdminScheduleApi.EDIT_SHIFT, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// update oncall and noshow api call.
export const updateNoshowAndOncall = createAsyncThunk('/manageAdminSchedule/updateNoshowAndOncall', async (request: IUpdateNoshowAndOncallRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageAdminScheduleApi.NOSHOW_ONCALL_UPDATE, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Notify CA api call.
export const nofifyCA = createAsyncThunk('/manageAdminSchedule/nofifyCA', async (notifyType: number, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageAdminScheduleApi.NOTIFY_CA, { notifyType });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Unpublish shift api call.
export const unpublishShift = createAsyncThunk('/manageAdminSchedule/unpublishShift', async (request: IPublishShift, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ManageAdminScheduleApi.UNPUBLISH_SHIFT, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Clear all admin schedule states to initial sate action.
export const revertAllAdminScheduleState = createAction(KKIActionTypes.REVERT_ALL_ADMIN_SCHEDULE_STATE);
