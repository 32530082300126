/**
 * @file   src\components\ProtectedRouteAdmin.tsx
 * @brief  This component used to manage and protect unauthorized/permission based url access for Admin(Scheduler/Manager).
 * @date   Sep, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { matchPath, Navigate, useLocation } from './ThirdPartyComponents';
import { ProtectedRouteProps } from '../interfaces/generalInterface';
import { getDataFromStorage } from '../utils/helper';
import { KKI_ATKN, STORAGE_USER } from '../utils/constants';
import { RoleTypes, AdminRoutePath, ManagerRoutePath } from '../utils/enums';

const ProtectedRouteAdmin = ({ children }: ProtectedRouteProps) => {
  /* Create location object to access location info. */
  const location = useLocation();

  // Access user information from the storage.
  const userInfo = getDataFromStorage(STORAGE_USER);
  // Access token information from the storage.
  const tokenInfo = getDataFromStorage(KKI_ATKN, true);
  const isAuthenticated =
    tokenInfo?.access_token !== '' && tokenInfo?.is_profile_completed === true && (userInfo.roleId === RoleTypes.Scheduler || userInfo.roleId === RoleTypes.Manager);

  /* Check whether the user is authenticated or not and redirected to login page. */
  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  const schRoutePaths: string[] = Object.values(AdminRoutePath);
  const managerRoutes: string[] = Object.values(ManagerRoutePath);
  if (
    (userInfo.roleId === RoleTypes.Scheduler || userInfo.roleId === RoleTypes.Manager) &&
    !schRoutePaths.some((url) => matchPath(url, location?.pathname)) &&
    userInfo.roleId === RoleTypes.Manager &&
    !managerRoutes.some((url) => matchPath(url, location?.pathname))
  ) {
    return <Navigate to={AdminRoutePath.ManageSchedule} state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRouteAdmin;
