/**
 * @file   src\components\AdminSwapCard.tsx
 * @brief  Admin swap request list component.
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import Strings from '../assests/strings/Strings.json';
import '../styles/ShiftCard.scss';
import { Accordion, Card, Col, Row, Button, useAccordionButton, moment } from './ThirdPartyComponents';
import { CustomToggleProps } from '../interfaces/generalInterface';
import { ISchSwapCard } from '../interfaces/swapInterface';
import Arrow from '../assests/icons/DownArrow';
import useAccordionCollapse from '../hooks/useAccordionCollapse';
import Pending from '../assests/icons/Pending';
import Approved from '../assests/icons/Publish';
import Declined from '../assests/icons/Declined';
import { SwapStatusByAdmin } from '../utils/enums';
import { DATE_FORMAT_DD_MMM, DATE_FORMAT_HHMMA, NIGHT_SHIFTS, DATE_FORMAT_MM_DD_YYYY_DDD, DATE_FORMAT_MM_DD_YYYY_HMMA_DDD } from '../utils/constants';
import { isCurrentTimeIsBeforeShiftStartTime } from '../utils/helper';

const CustomToggle = ({ children, eventKey, callback }: CustomToggleProps) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    callback(eventKey);
  });

  return (
    <button type="button" className="accordion-btn" onClick={decoratedOnClick}>
      {children}
    </button>
  );
};

const AdminSwapCard = ({ swapInfo, onApprove, handleDecline }: ISchSwapCard) => {
  // Get the collapsed state using the custom hook
  const { isCollapsed, toggleCollapse } = useAccordionCollapse();

  // Renders the status text and icon based on the swap request status.
  const renderStatus = (status: number) => {
    switch (status) {
      case SwapStatusByAdmin.Pending_Supervisor_Approval:
        return (
          <>
            {SwapStatusByAdmin[SwapStatusByAdmin.Pending_Supervisor_Approval].replace(/_/g, ' ')} <Pending />
          </>
        );
      case SwapStatusByAdmin.Approved:
        return (
          <>
            {Strings.Swap.Approved} <Approved />
          </>
        );
      case SwapStatusByAdmin.Declined:
        return (
          <>
            {Strings.Swap.Declined} <Declined />
          </>
        );
      default:
        return '';
    }
  };

  return (
    <Accordion activeKey={!isCollapsed ? '0' : null} className="shiftcard-main leave-card acc-lg-hide">
      <Card>
        <Card.Header className={`d-flex justify-content-between align-items-center ${isCollapsed ? 'collapse' : ''}`}>
          <Col className="d-flex align-items-center">
            {`${swapInfo.requestedShiftName} (${swapInfo.requestedShiftShortName})`} On {moment.utc(swapInfo.requestedShiftStartDateTime).format(DATE_FORMAT_DD_MMM)} &{' '}
            {`${swapInfo.partnerShiftName} (${swapInfo.partnerShiftShortName})`} On {moment.utc(swapInfo.partnerShiftStartDateTime).format(DATE_FORMAT_DD_MMM)}
          </Col>
          <CustomToggle eventKey="0" callback={toggleCollapse}>
            <Arrow />
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="shift-details">
            <Col className="shift-details-sub">
              <Row>
                <Col xs={12}>
                  {Strings.Swap.RequestedDate}
                  <span>{moment.utc(swapInfo.requestedDate).format(DATE_FORMAT_MM_DD_YYYY_DDD)}</span>
                </Col>
                <Col xs={6} lg={6} xl={7}>
                  {Strings.Swap.Requestor}
                  <span>{swapInfo.requesterName}</span>
                </Col>
                <Col xs={6} lg={6} xl={5}>
                  {Strings.Swap.RequestorShift}
                  <span>{`${swapInfo.requestedShiftName} (${swapInfo.requestedShiftShortName})`}</span>
                </Col>
                <Col xs={12}>
                  {Strings.Swap.ShiftTimings}
                  <span>
                    {NIGHT_SHIFTS.includes(swapInfo.requestedShiftShortName)
                      ? `${moment.utc(swapInfo.requestedShiftStartDateTime).format(DATE_FORMAT_MM_DD_YYYY_HMMA_DDD)} - ${moment
                          .utc(swapInfo.requestedShiftEndDateTime)

                          .format(DATE_FORMAT_MM_DD_YYYY_HMMA_DDD)}`
                      : `${moment.utc(swapInfo.requestedShiftStartDateTime).format(DATE_FORMAT_MM_DD_YYYY_HMMA_DDD)} - ${moment.utc(swapInfo.requestedShiftEndDateTime).format(DATE_FORMAT_HHMMA)}`}
                  </span>
                </Col>
                <Col xs={6} lg={6} xl={7}>
                  {Strings.Swap.Partner}
                  <span>{swapInfo.partnerName}</span>
                </Col>
                <Col xs={6} lg={6} xl={5}>
                  {Strings.Swap.PartnerShift}
                  <span>{`${swapInfo.partnerShiftName} (${swapInfo.partnerShiftShortName})`}</span>
                </Col>
                <Col xs={12} className="card-cell-mob">
                  {Strings.Swap.PartnerShiftTimings}
                  <span>
                    {NIGHT_SHIFTS.includes(swapInfo.partnerShiftShortName)
                      ? `${moment.utc(swapInfo.partnerShiftStartDateTime).format(DATE_FORMAT_MM_DD_YYYY_HMMA_DDD)} - ${moment
                          .utc(swapInfo.partnerShiftEndDateTime)

                          .format(DATE_FORMAT_MM_DD_YYYY_HMMA_DDD)}`
                      : `${moment.utc(swapInfo.partnerShiftStartDateTime).format(DATE_FORMAT_MM_DD_YYYY_HMMA_DDD)} - ${moment.utc(swapInfo.partnerShiftEndDateTime).format(DATE_FORMAT_HHMMA)}`}
                  </span>
                </Col>
              </Row>
            </Col>
            <Row className="align-items-center pt-3">
              <Col xs={12} md>
                {Strings.Label.Status}:<span className="fw-sb ps-1">{renderStatus(swapInfo.swapStatus)}</span>
              </Col>
              <Col xs={12} md="auto">
                {swapInfo.swapStatus === SwapStatusByAdmin.Pending_Supervisor_Approval &&
                isCurrentTimeIsBeforeShiftStartTime(swapInfo.requestedShiftStartDateTime || '') &&
                isCurrentTimeIsBeforeShiftStartTime(swapInfo.partnerShiftStartDateTime || '') ? (
                  <>
                    <Button variant="outline-primary" type="submit" onClick={handleDecline}>
                      {Strings.Button.Decline}
                    </Button>
                    <Button variant="primary" type="submit" onClick={onApprove}>
                      {Strings.Button.Approve}
                    </Button>
                  </>
                ) : swapInfo.swapStatus === SwapStatusByAdmin.Declined &&
                  isCurrentTimeIsBeforeShiftStartTime(swapInfo.requestedShiftStartDateTime || '') &&
                  isCurrentTimeIsBeforeShiftStartTime(swapInfo.partnerShiftStartDateTime || '') ? (
                  <>
                    {Strings.Label.Reason}:<span className="fw-sb ps-1">{swapInfo.declineReason}</span>
                  </>
                ) : (
                  ''
                )}
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default AdminSwapCard;
