/**
 * @file   src\utils\constants.ts
 * @brief  This file is responsible for defining constants.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

export const HTTP_STATUS_200 = 200;
export const HTTP_STATUS_401 = 401;
export const HTTP_STATUS_403 = 403;
export const HTTP_STATUS_404 = 404;
export const HTTP_STATUS_500 = 500;
export const API_TIMEOUT = 30000;
export const API_MAXCONTENTLENGTH = 1000;
export const API_REFRESH_TOKEN_ERROR = 400;
export const API_REDIRECTS = 5;
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_NUMBER_REGEX = /^\d{10}$/;
export const DEFAULT_OTP_TIMER = 60;
export const NUMBER_EMPTY = -1;
export const NUMBER_0 = 0;
export const NUMBER_1 = 1;
export const NUMBER_2 = 2;
export const NUMBER_3 = 3;
export const NUMBER_4 = 4;
export const NUMBER_5 = 5;
export const NUMBER_6 = 6;
export const NUMBER_7 = 7;
export const NUMBER_9 = 9;
export const NUMBER_10 = 10;
export const NUMBER_17 = 17;
export const NUMBER_30 = 30;
export const NUMBER_20 = 20;
export const NUMBER_40 = 40;
export const NUMBER_50 = 50;
export const NUMBER_100 = 100;
export const NUMBER_150 = 150;
export const NUMBER_200 = 200;
export const NUMBER_250 = 250;
export const STRING_0 = '0';
export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
export const DATE_FORMAT_DD_MMM_HHMMA = 'DD MMM hh:mm A';
export const DATE_FORMAT_HHMMA = 'hh:mm A';
export const TIME_FORMAT_HHMM = 'HH:mm';
export const DATE_FORMAT_DD_MMM_YYYY = 'DD MMM YYYY';
export const DATE_FORMAT_DD_MMM_YYYY_HHMMA = 'DD MMM YYYY, h:mm A';
export const DATE_FORMAT_DDD_DD_MMM_YYYY = 'ddd, DD MMM YYYY';
export const DATE_FORMAT_DDD_DD_MMM_YYYY_HHMMA = 'ddd, DD MMM YYYY, h:mm A';
export const DATE_FORMAT_DDD_DD_MMMM_YYYY = 'dddd, DD MMMM YYYY';
export const DATE_FORMAT_DD_MMM = 'DD MMM';
export const DATE_FORMAT_YYYY_MM_DD_HHMMA = 'YYYY-MM-DD hh:mm A';
export const DATE_FORMAT_YYYY_MM_DDTHH_MM_SS_Z = 'YYYY-MM-DDTHH:mm:ss[Z]';
export const CURRENT_DATE_FORMAT_WITH_OFFSET = 'YYYY-MM-DDTHH:mm:ssZ';
export const DATE_FORMAT_YYYY_MM_DD_HH_MM_SS = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT_HH_MM_SS = 'HH:mm:ss';
export const DATE_FORMAT_YYYY_MM_DDTHH_MM_SS_000Z = 'YYYY-MM-DDTHH:mm:ss.000[Z]';
export const DATE_FORMAT_MM_DD_YYYY_HMMA_DDD = 'MM/DD/YYYY (ddd) h:mm A';
export const DATE_FORMAT_MM_DD_YYYY_DDD = 'MM/DD/YYYY (ddd)';
export const DATE_FORMAT_MM_DD_YYYY = 'MM/DD/YYYY';
export const DATE_FORMAT_MM_DD_YYYY_FILTER = 'MM/dd/YYYY';
export const ALL_TEXT = 'All';
export const MOBILE_PIXELS = 768;
export const DATE_START = 'start';
export const DATE_END = 'end';
export const TIME_STARTS_FORMAT = '00:00:00';
export const TIME_END_FORMAT = '23:59:59';
export const DEAULT_TIME_FORMAT = 'T00:00:00.000Z';
export const NOSHOW_DEAULT_TIME_FORMAT = '00:00';
export const STORAGE_ROLE = 'KKI_RL';
export const NUMBER_ONLY_REGEX = /^[0-9]$/;
export const BACKSPACE_KEY = 'Backspace';
export const DELETE_KEY = 'Delete';
export const TAB_KEY = 'Tab';
export const ARROWLEFT_KEY = 'ArrowLeft';
export const ARROWRIGHT_KEY = 'ArrowRight';
export const COPY_KEY = 'Copy';
export const PASTE_KEY = 'Paste';
export const PAGE_SIZE = 10;
export const UNASSIGN = 'UNASSIGN';
export const CALLOUT = 'CALLOUT';
export const DEBOUNCE_DELAY = 300;
export const PARTTIME24 = 'partTime24';
export const PARTTIME16 = 'partTime16';
export const PARTTIME20 = 'partTime20';
export const FULLTIME = 'fullTime';
export const STORAGE_USER = 'KKI_USER_INFO';
export const USER_DETAILS = 'KKI_USER_DEATILS';
export const PHONE_COUNTRY_CODE = '+1';
export const QUERY_STRING_ARRAY_FORMAT = 'Repeat';
export const NOT_AVAILABLE_TEXT = '-';
export const NIGHT_SHIFTS = ['N8', 'N10'];
export const MID_SHIFTS = ['E4', 'E8', 'E10', 'W5', 'W8', 'P5', 'FS', 'A6', 'M4.5'];
export const DAY_SHIFTS = ['D8', 'D10'];
export const CA_NAME = 'caName';
export const USER_NAME = 'firstName';
export const MOT_POSITION = 'motPosition';
export const ROLE = 'role';
export const DESC = 'DESC';
export const CURRENT_PAGE = 'currentPage';
export const ASC = 'ASC';
export const OTHER_LEAVE_REASON_TYPE = 15;
export const SCHEDULER = 'Scheduler';
export const MANAGER = 'Manager';
export const OTHER_REASON_LABEL = 'Other';
export const INJURY_REASON_LABEL = 'Injury';
export const TYPE = 'type';
export const STORAGE_MANAGE_SCHEDULE_FILTER = 'KKI_MANAGE_SCHEDULE_FILTER';
export const STORAGE_VIEW_SCHEDULE_FILTER = 'KKI_VIEW_SCHEDULE_FILTER';
export const SESSION_TYPE_TEXT = 'sout';
export const KKI_ATKN = 'KKI_ATKN';
export const MONTHS_LABEL = 'months';
export const NULL_DATE = '1970-01-01T00:00:00Z';
