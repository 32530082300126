/**
 * @file   src\components\LeaveCard.tsx
 * @brief  Component for Leave details.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React from 'react';
import Strings from '../assests/strings/Strings.json';
import '../styles/ShiftCard.scss';
import { Accordion, Card, Col, Row, useAccordionButton, moment } from './ThirdPartyComponents';
import { ILeaveCardProps } from '../interfaces/leaveInterface';
import Arrow from '../assests/icons/DownArrow';
import Approved from '../assests/icons/Publish';
import Declined from '../assests/icons/Declined';
import { LeaveStatus, LeaveTypes } from '../utils/enums';
import { DATE_FORMAT_MM_DD_YYYY_DDD } from '../utils/constants';
import useAccordionCollapse from '../hooks/useAccordionCollapse';
import { CustomToggleProps } from '../interfaces/generalInterface';

const CustomToggle = ({ children, eventKey, callback }: CustomToggleProps) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    callback(eventKey);
  });

  return (
    <button type="button" className="accordion-btn" onClick={decoratedOnClick}>
      {children}
    </button>
  );
};
const LeaveCard = ({ data }: ILeaveCardProps) => {
  // Get the collapsed state using the custom hook
  const { isCollapsed, toggleCollapse } = useAccordionCollapse();

  return (
    <Accordion activeKey={!isCollapsed ? '0' : null} className="shiftcard-main leave-card">
      {data ? (
        <Card>
          <Card.Header className={`d-flex justify-content-between align-items-center ${isCollapsed ? 'collapse' : ''}`}>
            {`${LeaveTypes[data.leaveType].replace(/_/g, ' ')} Leave`}
            <div className="d-flex align-items-center">
              <div className="status">
                {data.leaveStatus === LeaveStatus.Approved ? (
                  <span className="published">
                    <Approved />
                    {Strings.Leave.Approved}
                  </span>
                ) : (
                  ''
                )}
                {data.leaveStatus === LeaveStatus.Declined ? (
                  <span className="declined">
                    <Declined />
                    {Strings.Leave.Declined}
                  </span>
                ) : (
                  ''
                )}
              </div>
              <CustomToggle eventKey="0" callback={toggleCollapse}>
                <Arrow />
              </CustomToggle>
            </div>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="shift-details">
              <Col className="shift-details-sub">
                <Row>
                  <Col xl md={6} sm={6} xs>
                    {Strings.Leave.RequistID}
                    <span>{data.leaveRequestId}</span>
                  </Col>
                  <Col xl md={6} sm={6} xs="auto" className="sm-text-end border-bottom">
                    {Strings.Leave.TotalLeaveDays}
                    <span>{data.noOfLeaves}</span>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6} md={6} sm={12} className="border-bottom-sm">
                    {Strings.Leave.DateRange}
                    <span>{`${moment(data.leaveFrom).format(DATE_FORMAT_MM_DD_YYYY_DDD)} To ${moment(data.leaveTo).format(DATE_FORMAT_MM_DD_YYYY_DDD)}`}</span>
                  </Col>
                  <Col xl={6} md={6} sm={12}>
                    {Strings.Leave.Reason}
                    <span>{data.reason}</span>
                  </Col>
                </Row>
              </Col>
              {data.leaveStatus === LeaveStatus.Declined ? (
                <Col className="shift-details-sub mt-2">
                  <Row>
                    <Col xs={12} lg={6} xl={6} xxl>
                      {Strings.Leave.DeclineReason}
                      <span>{data.declinedReason}</span>
                    </Col>
                  </Row>
                </Col>
              ) : (
                ' '
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      ) : (
        <>No data found.</>
      )}
    </Accordion>
  );
};
export default LeaveCard;
