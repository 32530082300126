/**
 * @file   src\validations\manageScheduleSchema.ts
 * @brief  This file is responsible for defining schedule/shifts related form validation schemas.
 * @date   Aug, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import * as yup from 'yup';
import {
  CALLOUT_REASON_REQUIRED,
  CALLOUT_REASON_TYPE_REQUIRED,
  CREATESHIFT_BLUE_REQUIRED,
  CREATESHIFT_CA_REQUIRED,
  CREATESHIFT_ENDDATE_REQUIRED,
  CREATESHIFT_ENDTIME_REQUIRED,
  CREATESHIFT_LD_REQUIRED,
  CREATESHIFT_RED_REQUIRED,
  CREATESHIFT_SHIFTDAYS_REQUIRED,
  CREATESHIFT_SHIFT_REQUIRED,
  CREATESHIFT_STARTDATE_REQUIRED,
  CREATESHIFT_STARTTIME_REQUIRED,
  CREATESHIFT_WHITE_REQUIRED,
  CREATESHIFT_YELLOW_REQUIRED,
} from '../messages/validationMessages';
import { CreateShiftTypes } from '../utils/enums';
import { NUMBER_17 } from '../utils/constants';

// Create shift form validation schema.
export const CREATE_SHIFT_SCHEMA = yup.object({
  shiftTypeId: yup.number().required(CREATESHIFT_SHIFT_REQUIRED).min(1, CREATESHIFT_SHIFT_REQUIRED),
  requiredCA: yup.number().required(CREATESHIFT_CA_REQUIRED).min(1, CREATESHIFT_CA_REQUIRED),
  isYellowRequired: yup.boolean(),
  isBlueRequired: yup.boolean(),
  isRedRequired: yup.boolean(),
  isWhiteRequired: yup.boolean(),
  isLDRequired: yup.boolean(),
  requiredYellow: yup.string().trim().required(CREATESHIFT_YELLOW_REQUIRED),
  requiredBlue: yup.string().trim().required(CREATESHIFT_BLUE_REQUIRED),
  requiredRed: yup.string().trim().required(CREATESHIFT_RED_REQUIRED),
  requiredWhite: yup.string().trim().required(CREATESHIFT_WHITE_REQUIRED),
  requiredLD: yup.string().trim().required(CREATESHIFT_LD_REQUIRED),
  createShiftType: yup.number(),
  shiftStartTime: yup.string().trim().required(CREATESHIFT_STARTTIME_REQUIRED),
  shiftEndTime: yup.string().trim().required(CREATESHIFT_ENDTIME_REQUIRED),
  shiftStartDate: yup.string().trim().required(CREATESHIFT_STARTDATE_REQUIRED),
  shiftEndDate: yup.string().when('createShiftType', {
    is: Number(CreateShiftTypes.Recurring),
    then: (schema) => schema.required(CREATESHIFT_ENDDATE_REQUIRED),
    otherwise: (schema) => schema.notRequired(), // Skip validation if createShiftType is not recurring.
  }),
  shiftDays: yup.array().when('createShiftType', {
    is: Number(CreateShiftTypes.Recurring),
    then: (schema) => schema.required(CREATESHIFT_SHIFTDAYS_REQUIRED).min(1, CREATESHIFT_SHIFTDAYS_REQUIRED),
    otherwise: (schema) => schema.notRequired(), // Skip validation if createShiftType is not recurring.
  }),
});

// Callout reason form validation schema.
export const CALLOUT_REASON_SCHEMA = yup.object({
  callOutReasonType: yup.number().required(CALLOUT_REASON_TYPE_REQUIRED),
  reason: yup.string().when('callOutReasonType', {
    is: NUMBER_17,
    then: (schema) => schema.required(CALLOUT_REASON_REQUIRED),
    otherwise: (schema) => schema.notRequired(), // Skip validation if leaveReasonType is not other.
  }),
});
