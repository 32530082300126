/**
 * @file   src\containers\Scheduler\ManageProfile\ViewProfile.tsx
 * @brief  Scheduler Profile page.
 * @date   August, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Col, Row, Button, useNavigate, moment, useEffect } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import Edit from '../../../assests/icons/Edit';
import { AdminRoutePath, RoleTypes } from '../../../utils/enums';
import { DATE_FORMAT_MM_DD_YYYY, NOT_AVAILABLE_TEXT, SCHEDULER, MANAGER } from '../../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';
import { getAdminProfile, revertAllUserState } from '../../../store/actions/userAction';
import { formatPhoneNumber } from '../../../utils/helper';
import Verified from '../../../assests/images/verify-icon.svg';
import Loader from '../../../components/Loader';

const Profile = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const { isProfileFetchLoading, profileInfo } = useAppSelector((state: RootState) => state.user);

  // Component initial loading sideEffect to fetch profile api.
  useEffect(() => {
    dispatch(getAdminProfile());
    return () => {
      dispatch(revertAllUserState());
    };
  }, []);

  return (
    <div>
      <div className="pageheader d-flex justify-content-between">
        <h1>{Strings.Header.AdminProfile}</h1>
        <Button variant="outline-secondary" onClick={() => navigate(AdminRoutePath.SCHEditProfile)}>
          <Edit />
          {Strings.Button.Edit}
        </Button>
      </div>
      <div className="profile-main border">
        <Row>
          <Col xl={4} lg={6} md={6} sm={12} className="profile-secondary">
            <Col className="mb-0">
              {Strings.Label.Name}
              <span className="d-block fw-sb">
                <span>{profileInfo?.firstName ? `${profileInfo?.firstName} ${profileInfo.lastName}` : NOT_AVAILABLE_TEXT}</span>
              </span>
            </Col>
          </Col>
          <Col xl={4} lg={6} md={6} sm={12} className="profile-secondary">
            {Strings.Label.EmpId}
            <span className="d-block fw-sb">{profileInfo?.empId ?? NOT_AVAILABLE_TEXT}</span>
          </Col>
          <Col xl={4} lg={6} md={6} sm={12} className="profile-secondary">
            {Strings.Label.Role}
            <span className="d-block fw-sb">{profileInfo?.roleId === RoleTypes.Scheduler ? SCHEDULER : RoleTypes.Manager ? MANAGER : ''}</span>
          </Col>
          <Col xl={4} lg={6} md={6} sm={12} className="profile-secondary">
            {Strings.Label.Email}
            <span className="d-block fw-sb">{profileInfo?.email || NOT_AVAILABLE_TEXT}</span>
          </Col>
          <Col xl={4} lg={6} md={6} sm={12} className="profile-secondary">
            {Strings.SetupProfile.HireDate}
            <span className="d-block fw-sb">{profileInfo?.hireDate ? moment(profileInfo?.hireDate).local().format(DATE_FORMAT_MM_DD_YYYY) : NOT_AVAILABLE_TEXT}</span>
          </Col>
          <Col xl={4} lg={6} md={6} sm={12} className="profile-secondary">
            {Strings.Label.Mobile}
            <span className="d-block fw-sb">
              {profileInfo?.phone && formatPhoneNumber(profileInfo?.phone)} <img src={Verified} alt="verified" />
            </span>
          </Col>
          <Col xl={4} lg={6} md={6} sm={12} className="profile-secondary">
            {Strings.SetupProfile.ReceiveSMS}
            <span className="d-block fw-sb">{profileInfo?.isSubscribed ? Strings.SetupProfile.Subscribed : Strings.SetupProfile.NotSubscribed}</span>
          </Col>
        </Row>
      </div>
      {isProfileFetchLoading && <Loader />}
    </div>
  );
};
export default Profile;
