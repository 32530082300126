/**
 * @file   src\containers\Scheduler\ManageLeave\ManageLeave.tsx
 * @brief  Leave listing page
 * @date   August, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Row, Col, Filter, Button, moment, useState, Modal, useEffect } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import Close from '../../../assests/icons/Close';
import KKISelect from '../../../components/KKISelect';
import LeaveCard from '../../../components/AdminLeaveCard';
import { IGetAllLeaveRequest, ILeaveDeclineReasons, ILeaveDetails } from '../../../interfaces/leaveInterface';
import {
  ALL_TEXT,
  DATE_END,
  DATE_FORMAT_MM_DD_YYYY,
  DATE_FORMAT_MM_DD_YYYY_DDD,
  DATE_FORMAT_MM_DD_YYYY_FILTER,
  DATE_FORMAT_YYYY_MM_DD,
  DATE_START,
  HTTP_STATUS_200,
  MONTHS_LABEL,
  NUMBER_0,
  NUMBER_1,
  NUMBER_3,
  NUMBER_EMPTY,
  OTHER_REASON_LABEL,
  STORAGE_USER,
} from '../../../utils/constants';
import { DateTimeCalculatedTypes, LeaveStatus, LeaveTypes, OperationTypes, RoleTypes } from '../../../utils/enums';
import { IObject } from '../../../interfaces/generalInterface';
import KKIInput from '../../../components/KKIInput';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { approveLeave, declineLeave, getAllLeaves, getDeclineLeaveReasons, revertAllLeaveState } from '../../../store/actions/manageLeaveAction';
import { RootState } from '../../../store';
import { MessageToaster } from '../../../utils/toastUtils';
import { validateForm } from '../../../utils/validationHelper';
import { LEAVE_DECLINE_SCHEMA } from '../../../validations/manageLeaveSchema';
import Loader from '../../../components/Loader';
import { formatPhoneNumber, getCalculatedDateTime, getDataFromStorage } from '../../../utils/helper';
import KKIDatepicker from '../../../components/KKIDatepicker';
import { DATE_RANGE_VALIDATION_MESSAGE } from '../../../messages/validationMessages';

// Toast object creation.
const toast = new MessageToaster();

const SCHManageLeave = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access user information from the storage.
  const userInfo = getDataFromStorage(STORAGE_USER);

  // Access redux state variables.
  const {
    isLeaveLoading,
    leaveListResponse,
    isDeclineLeaveReasonsSuccess,
    declineLeaveReasonsErrorCode,
    declineLeaveReasonsMessage,
    declineLeaveReasons,
    isApproveLeaveLoading,
    isApproveLeaveSuccess,
    approveLeaveErrorCode,
    approveLeaveMessage,
    isDeclineLeaveLoading,
    isDeclineLeaveSuccess,
    declineLeaveErrorCode,
    declineLeaveMessage,
  } = useAppSelector((state: RootState) => state.manageLeave);

  // Initialize component state variables.
  const [isFilterOpen, setFilterOpen] = useState<boolean>(false);
  const [fromDateFilter, setFromDateFilter] = useState<Date | null>(moment().toDate());
  const [toDateFilter, setToDateFilter] = useState<Date | null>(getCalculatedDateTime(DateTimeCalculatedTypes.Month, OperationTypes.Add, NUMBER_1, moment().toDate()));
  const [leaveType, setLeaveType] = useState<IObject | null>({ label: ALL_TEXT, value: NUMBER_0.toString() });
  const [leaveStatus, setLeaveStatus] = useState<IObject | null>({ label: ALL_TEXT, value: NUMBER_0.toString() });
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [declineReason, setDeclineReason] = useState<string>('');
  const [errorFields, setErrorFields] = useState<IObject | null>(null);
  const [selectedLeaveId, setSelectedLeaveId] = useState<number>(NUMBER_0);
  const [leaveReasonList, setLeaveReasonList] = useState<IObject[]>([]);
  const [selectedReason, setSelectedReason] = useState<IObject | null>(null);
  const [showApproveConfirmPopup, setApproveConfirmPopup] = useState<boolean>(false);
  const [showDeclineConfirmPopup, setDeclineConfirmPopup] = useState<boolean>(false);
  const [pageSize] = useState<number>(NUMBER_0);
  const [pageIndex] = useState<number>(NUMBER_0);

  // Method to fetch leaves.
  const fetchLeaves = () => {
    const leaveRequest: IGetAllLeaveRequest = {
      userType: userInfo?.roleId || RoleTypes.Scheduler,
      fromDate: fromDateFilter ? moment(new Date(fromDateFilter)).format(DATE_FORMAT_YYYY_MM_DD) : '',
      toDate: toDateFilter ? moment(new Date(toDateFilter)).format(DATE_FORMAT_YYYY_MM_DD) : '',
      leaveType: Number(leaveType?.value),
      status: Number(leaveStatus?.value),
      page: pageIndex,
      limit: pageSize,
    };
    dispatch(getAllLeaves(leaveRequest));
  };

  // Component initial loading side effect handler.
  useEffect(() => {
    fetchLeaves();
    return () => {
      dispatch(revertAllLeaveState());
    };
  }, []);

  // Decline leave reasons api response state change.
  useEffect(() => {
    if (isDeclineLeaveReasonsSuccess && declineLeaveReasonsErrorCode === HTTP_STATUS_200) {
      if (declineLeaveReasons && declineLeaveReasons.length > 0) {
        const reasonOptions = declineLeaveReasons.map((leave: ILeaveDeclineReasons): IObject => ({ label: leave.reason, value: leave.reasonId.toString() }));
        setLeaveReasonList(reasonOptions);
        setSelectedReason(reasonOptions[0] ?? null);
      }
    } else if (!isDeclineLeaveReasonsSuccess && declineLeaveReasonsErrorCode > HTTP_STATUS_200 && declineLeaveReasonsMessage) {
      toast.toastError(declineLeaveReasonsMessage);
      dispatch(revertAllLeaveState());
    }
  }, [isDeclineLeaveReasonsSuccess, declineLeaveReasonsErrorCode, declineLeaveReasonsMessage, declineLeaveReasons]);

  // Approve swap request api response state change.
  useEffect(() => {
    if (isApproveLeaveSuccess && approveLeaveErrorCode === HTTP_STATUS_200 && approveLeaveMessage) {
      toast.toastSuccess(approveLeaveMessage);
      setShowDeclineModal(false);
      dispatch(revertAllLeaveState());
      fetchLeaves();
    } else if (!isApproveLeaveSuccess && approveLeaveErrorCode > HTTP_STATUS_200 && approveLeaveMessage) {
      toast.toastError(approveLeaveMessage);
      dispatch(revertAllLeaveState());
    }
  }, [isApproveLeaveSuccess, approveLeaveErrorCode, approveLeaveMessage]);

  // Decline swap request api response state change.
  useEffect(() => {
    if (isDeclineLeaveSuccess && declineLeaveErrorCode === HTTP_STATUS_200 && declineLeaveMessage) {
      toast.toastSuccess(declineLeaveMessage);
      setShowDeclineModal(false);
      dispatch(revertAllLeaveState());
      fetchLeaves();
    } else if (!isDeclineLeaveSuccess && declineLeaveErrorCode > HTTP_STATUS_200 && declineLeaveMessage) {
      toast.toastError(declineLeaveMessage);
      dispatch(revertAllLeaveState());
    }
  }, [isDeclineLeaveSuccess, declineLeaveErrorCode, declineLeaveMessage]);

  // Leave status filter options.
  const status = [
    { label: ALL_TEXT, value: NUMBER_0.toString() },
    { label: LeaveStatus[LeaveStatus.Approved], value: LeaveStatus.Approved },
    { label: LeaveStatus[LeaveStatus.Declined], value: LeaveStatus.Declined },
    { label: LeaveStatus[LeaveStatus.Pending_Approval].replace(/_/g, ' '), value: LeaveStatus.Pending_Approval },
  ];

  // Leave types filter options.
  const leaveTypes = [
    { label: ALL_TEXT, value: NUMBER_0.toString() },
    { label: LeaveTypes[LeaveTypes.Sick].replace(/_/g, ' '), value: LeaveTypes.Sick.toString() },
    { label: LeaveTypes[LeaveTypes.Bereavement].replace(/_/g, ' '), value: LeaveTypes.Bereavement.toString() },
    { label: LeaveTypes[LeaveTypes.Vacation_Day].replace(/_/g, ' '), value: LeaveTypes.Vacation_Day.toString() },
    { label: LeaveTypes[LeaveTypes.Floating_Holiday].replace(/_/g, ' '), value: LeaveTypes.Floating_Holiday.toString() },
    { label: LeaveTypes[LeaveTypes.Free_Days].replace(/_/g, ' '), value: LeaveTypes.Free_Days.toString() },
  ];

  // Filter change handler
  const handleFilterChange = (state: { isOpen: boolean }) => {
    setFilterOpen(state.isOpen);
  };

  // Leave status filter dropdown select change handler.
  const onLeaveStatusSelect = (val: IObject) => {
    setLeaveStatus(val);
  };

  // Leave type dropdown select change handler.
  const onLeaveTypeSelect = (val: IObject) => {
    setLeaveType(val);
  };

  // Leave decline reason type dropdown select change handler.
  const onDeclineReasonSelect = (val: IObject) => {
    setSelectedReason(val);
  };

  // Decline reason input change handler.
  const onDeclineReasonChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const leaveDeclineForm = {
      leaveReasonType: selectedReason?.value ? Number(selectedReason.value) : NUMBER_EMPTY,
      reason: value,
    };
    const errorResult = await validateForm(leaveDeclineForm, LEAVE_DECLINE_SCHEMA, errorFields);
    setErrorFields(errorResult);
    setDeclineReason(value);
  };

  // Apply filters to leave requests.
  const applyFilters = () => {
    if (!fromDateFilter || !toDateFilter) {
      fetchLeaves();
      setFilterOpen(false);
    } else if (moment(toDateFilter).diff(moment(fromDateFilter), MONTHS_LABEL, true) > NUMBER_3) {
      toast.toastError(DATE_RANGE_VALIDATION_MESSAGE);
    } else {
      fetchLeaves();
      setFilterOpen(false);
    }
  };

  // Reset leave filter button click event handler.
  const resetFilter = () => {
    setFromDateFilter(null);
    setToDateFilter(null);
    onLeaveTypeSelect({ label: ALL_TEXT, value: NUMBER_0.toString() });
    setLeaveStatus({ label: ALL_TEXT, value: NUMBER_0.toString() });
  };

  // Function to handle the decline action.
  const handleDecline = (leaveId: number) => {
    dispatch(getDeclineLeaveReasons());
    setSelectedLeaveId(leaveId);
    setDeclineReason('');
    setShowDeclineModal(true);
  };

  // Function to confirm decline.
  const confirmDecline = async () => {
    const leaveDeclineForm = {
      leaveReasonType: selectedReason?.value || 0,
      reason: declineReason,
    };
    const errorResult = await validateForm(leaveDeclineForm, LEAVE_DECLINE_SCHEMA, errorFields);
    setErrorFields(errorResult);
    if (Object.keys(errorResult).length === 0) {
      setDeclineConfirmPopup(true);
    }
  };

  // Function to handle the approve action
  const handleApprove = (leaveId: number) => {
    setSelectedLeaveId(leaveId);
    setApproveConfirmPopup(true);
  };

  // Approve leave confirm event.
  const onLeaveApprove = () => {
    setApproveConfirmPopup(false);
    if (selectedLeaveId > NUMBER_0) {
      dispatch(approveLeave({ leaveRequestId: selectedLeaveId }));
    }
  };

  // Decline leave confirm event.
  const onLeaveDecline = () => {
    setDeclineConfirmPopup(false);
    if (selectedLeaveId > NUMBER_0 && selectedReason) {
      dispatch(declineLeave({ leaveRequestId: selectedLeaveId, reasonId: Number(selectedReason.value), description: declineReason }));
    }
  };

  // Start/end filter date input select event handler.
  const handleDateFilterChange = (date: Date | null, type: string) => {
    if (type === DATE_START) {
      setFromDateFilter(date);
      if (!toDateFilter || (toDateFilter && moment(date).isAfter(moment(toDateFilter)))) {
        setToDateFilter(date);
      }
    } else {
      setFromDateFilter((prev) => prev || date);
      setToDateFilter(date);
    }
  };

  return (
    <div>
      <div className="pageheader">
        <h1>{Strings.HD.ManageLeave}</h1>
      </div>
      <div className="filter-main">
        <Row className="d-flex justify-content-between align-items-center">
          <Col xxl={4} xl={4} lg={5} md={6} xs="auto" className="pe-0">
            <h2 className="mb-0">{Strings.HD.CALeaveRequests}</h2>
          </Col>
          <Col xs="auto" className="btn-container d-flex ps-0">
            <Filter
              width={310}
              right
              pageWrapId="filter-wrapper"
              outerContainerId="outer-container"
              isOpen={isFilterOpen}
              onStateChange={handleFilterChange}
              customCrossIcon={<Close />}
              bodyClassName="overflow-hidden"
            >
              <div id="filter-wrapper" className="filter-overlay">
                <div className="overlay-hd p-3 border-bottom">
                  <h1>{Strings.Filter.Filter}</h1>
                </div>
                <div className="overlay-filter-content">
                  <div className="mb-3">
                    <KKIDatepicker
                      id="fromDateFilter"
                      name="fromDateFilter"
                      placeholder={Strings.Schedule.DateFrom}
                      label={Strings.Schedule.DateFrom}
                      value={fromDateFilter}
                      dateFormat={DATE_FORMAT_MM_DD_YYYY_FILTER}
                      alert={errorFields?.hireDate || ''}
                      onChangeDatepicker={(date: any) => handleDateFilterChange(date, DATE_START)}
                    />
                  </div>
                  <div className="mb-3">
                    <KKIDatepicker
                      id="toDateFilter"
                      name="toDateFilter"
                      placeholder={Strings.Schedule.DateFilterText}
                      label={Strings.Schedule.DateFilterText}
                      value={toDateFilter}
                      dateFormat={DATE_FORMAT_MM_DD_YYYY_FILTER}
                      alert={errorFields?.hireDate || ''}
                      minDate={moment(fromDateFilter).toDate()}
                      onChangeDatepicker={(date: any) => handleDateFilterChange(date, DATE_END)}
                    />
                  </div>
                  <div className="mb-3">
                    <KKISelect
                      id="signupselect"
                      label={Strings.Label.LeaveType}
                      name="signupselect"
                      className="custom-select"
                      placeholder={Strings.Label.LeaveType}
                      searchvalue={false}
                      options={leaveTypes}
                      value={leaveType}
                      onSelectChange={onLeaveTypeSelect}
                    />
                  </div>

                  <div>
                    <KKISelect
                      id="signupselect"
                      label={Strings.Label.Status}
                      name="signupselect"
                      className="custom-select"
                      placeholder={Strings.Label.Status}
                      searchvalue={false}
                      options={status}
                      value={leaveStatus}
                      onSelectChange={onLeaveStatusSelect}
                    />
                  </div>
                </div>

                <Col className="overlay-button-container">
                  <Button variant="outline-primary" onClick={resetFilter}>
                    {Strings.Button.Reset}
                  </Button>
                  <Button variant="primary" onClick={applyFilters}>
                    {Strings.Button.Apply}
                  </Button>
                </Col>
              </div>
            </Filter>
          </Col>
        </Row>
      </div>
      <Row>
        {leaveListResponse &&
          leaveListResponse.leaves?.length > 0 &&
          leaveListResponse.leaves.map((leave: ILeaveDetails) => (
            <Col lg={6} md={12} key={leave.leaveRequestId}>
              <LeaveCard
                leaveType={leave.leaveType}
                totalLeaveDays={leave.noOfLeaves}
                requistID={leave.leaveRequestId}
                requestor={leave.requesterName}
                contact={formatPhoneNumber(leave.requesterPhone || '')}
                startDate={moment(leave.leaveFrom).format(DATE_FORMAT_MM_DD_YYYY_DDD)}
                endDate={moment(leave.leaveTo).format(DATE_FORMAT_MM_DD_YYYY_DDD)}
                status={leave.leaveStatus}
                declineReason={leave.declinedReason || ''}
                handleApprove={() => handleApprove(leave.leaveRequestId)}
                handleDecline={() => handleDecline(leave.leaveRequestId)}
              />
            </Col>
          ))}
        {(!leaveListResponse || (leaveListResponse && leaveListResponse.leaves?.length === 0)) && <div className="mt-5 text-center w-100">{Strings.Text.NoDataFound}</div>}
      </Row>

      <Modal show={showDeclineModal} onHide={() => setShowDeclineModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{Strings.HD.ReasonForDecline}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col className="mb-3">
            <KKISelect
              id="reasonTypes"
              label={Strings.Label.SelectReason}
              name="reasonTypes"
              className="custom-select"
              placeholder={Strings.Label.SelectReason}
              searchvalue={false}
              options={leaveReasonList}
              value={selectedReason}
              onSelectChange={onDeclineReasonSelect}
            />
          </Col>
          {selectedReason?.label === OTHER_REASON_LABEL && (
            <Col>
              <KKIInput
                id="declineReason"
                label={Strings.Label.EnterReason}
                name="declineReason"
                type="text"
                as="textarea"
                rows={3}
                placeholder={Strings.Leave.PlaceHolder.EnterReason}
                value={declineReason}
                onChange={onDeclineReasonChange}
                alert={errorFields?.reason || ''}
              />
            </Col>
          )}
          <Col className="button-container-right text-end mt-4">
            <Button variant="outline-primary" onClick={() => setShowDeclineModal(false)}>
              {Strings.Button.Cancel}
            </Button>
            <Button variant="primary" onClick={confirmDecline}>
              {Strings.Button.Submit}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={showApproveConfirmPopup} onHide={() => setApproveConfirmPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.LeaveApprovePopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => setApproveConfirmPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onLeaveApprove()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={showDeclineConfirmPopup} onHide={() => setDeclineConfirmPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.LeaveDeclinePopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => setDeclineConfirmPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onLeaveDecline()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      {(isLeaveLoading || isApproveLeaveLoading || isDeclineLeaveLoading) && <Loader />}
    </div>
  );
};
export default SCHManageLeave;
