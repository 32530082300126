/**
 * @file   src\containers\Scheduler\Schedule\CAResource.tsx
 * @brief  CA Resource list page
 * @date   August, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import React, { useEffect } from 'react';
import { Row, Col, Breadcrumb, useState, Button, useParams, moment, useLocation, useNavigate } from '../../../components/ThirdPartyComponents';
import Strings from '../../../assests/strings/Strings.json';
import AssignCACard from '../../../components/AssignCACard';
import EmployeeCard from '../../../components/EmployeeCard';
import KKICheckbox from '../../../components/KKICheckbox';
import { AdminRoutePath, NavigationToEmployeeCardFrom } from '../../../utils/enums';
import { CURRENT_DATE_FORMAT_WITH_OFFSET, HTTP_STATUS_200, NUMBER_0, NUMBER_1, NUMBER_2, NUMBER_3, NUMBER_4 } from '../../../utils/constants';
import PDFIcon from '../../../assests/icons/PDF';
import { ICADetails } from '../../../interfaces/caInterface';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { exportCallOutList, getCallOnCAList, revertAllCalloutState, sendCallOnMessages } from '../../../store/actions/manageCallOutShiftAction';
import { RootState } from '../../../store';
import Loader from '../../../components/Loader';
import { getShiftDetails } from '../../../store/actions/manageCAScheduleAction';
import { ICA } from '../../../interfaces/scheduleInterface';
import { MessageToaster } from '../../../utils/toastUtils';
import { IcalloutUserInfo } from '../../../interfaces/callOutShiftInterface';

// Toast object creation.
const toast = new MessageToaster();

const CAResource = () => {
  // Create current location object.
  const location = useLocation();

  // Navigate object creation.
  const navigate = useNavigate();

  // Create route param object to access the route parameters.
  const params = useParams();

  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Access location state variables.
  const calloutFilterType = location.state?.calloutFilterType || null;

  // Access redux state variables.
  const {
    isFetchCallOnCALoading,
    callOnCAList,
    isExportCallOutLoading,
    isExportCallOutSuccess,
    exportCallOutErrorCode,
    exportCallOutMessage,
    pdfData,
    isSendCallOnSmsLoading,
    isSendCallOnSmsSuccess,
    sendCallOnSmsErrorCode,
    sendCallOnSmsMessage,
  } = useAppSelector((state: RootState) => state.manageCallOut);
  const { isShiftDetailsLoading, shiftDetails } = useAppSelector((state: RootState) => state.manageCASchedule);

  // Initialize component stat variables.
  const [selectedEmployees, setSelectedEmployees] = useState<ICA[]>([]);
  const [userInfo, setUserInfo] = useState<IcalloutUserInfo[]>([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState<boolean>(false);

  /* Access and set the location url parameters. */
  const shiftId: number = params.shiftId ? Number(params.shiftId) : NUMBER_0;
  const shiftFromTime: string | null = params.shiftFromTime || null;
  const shiftDate: string | null = params.shiftDate || '';

  // Component initial loading.
  useEffect(() => {
    // Fetch callout CA list if request parameters are valid.
    if (shiftId > NUMBER_0 && shiftFromTime && shiftDate) {
      dispatch(
        getCallOnCAList({
          shiftId,
          shiftDate: shiftDate !== '-' ? shiftDate : '',
          shiftFromTime: shiftFromTime !== '-' ? shiftFromTime : '',
          currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET),
        }),
      );
    }
    // Fetch shift details.
    dispatch(getShiftDetails({ shiftId, currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET) }));
    return () => {
      dispatch(revertAllCalloutState());
    };
  }, []);

  // Update the "Select All" checkbox state based on individual checkbox states.
  useEffect(() => {
    const allEmployeeIDs = callOnCAList ? callOnCAList.map((ca) => ca.caId) : [];
    const selectedEmployeeIDs = selectedEmployees.map((employee) => employee.caId);
    setIsSelectAllChecked(allEmployeeIDs.length > 0 && allEmployeeIDs.length === selectedEmployeeIDs.length);
  }, [selectedEmployees]);

  // Notify CA regarding callout shift api response state change.
  useEffect(() => {
    if (isSendCallOnSmsSuccess && sendCallOnSmsErrorCode === HTTP_STATUS_200 && sendCallOnSmsMessage) {
      toast.toastSuccess(sendCallOnSmsMessage);
      setSelectedEmployees([]);
      setUserInfo([]);
    } else if (!isSendCallOnSmsSuccess && sendCallOnSmsErrorCode > HTTP_STATUS_200 && sendCallOnSmsMessage) {
      toast.toastError(sendCallOnSmsMessage);
    }
  }, [isSendCallOnSmsSuccess, sendCallOnSmsErrorCode, sendCallOnSmsMessage]);

  // Export pdf api response state change handling.
  useEffect(() => {
    if (isExportCallOutSuccess && pdfData) {
      const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Create a link element, set download attributes, and trigger a click
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.setAttribute('download', 'CallOut-CA-List.pdf'); // Set the PDF filename
      document.body.appendChild(link);
      link.click();
      toast.toastSuccess(Strings.Text.CalloutCAExportSuccessText);
      // Cleanup: remove the link and revoke the URL
      document.body.removeChild(link);
      URL.revokeObjectURL(pdfUrl);
      dispatch(revertAllCalloutState());
    } else if (!isExportCallOutSuccess && exportCallOutErrorCode > HTTP_STATUS_200 && exportCallOutMessage) {
      toast.toastError(exportCallOutMessage);
      dispatch(revertAllCalloutState());
    }
  }, [isExportCallOutSuccess, exportCallOutErrorCode, exportCallOutMessage, pdfData]);

  // Handles the checkbox change event.
  const handleCheckboxChange = (isChecked: boolean, caDetails: ICADetails) => {
    const { caId, phone } = caDetails;
    if (isChecked) {
      setSelectedEmployees((prevSelected) => [
        ...prevSelected,
        {
          caId,
        },
      ]);
      if (phone && !userInfo.some((item: IcalloutUserInfo) => item.user === caId)) {
        setUserInfo((info) => [...info, { user: caId, phone }]);
      }
    } else {
      setSelectedEmployees((prevSelected) => prevSelected.filter((entry) => entry.caId !== caId));
      setUserInfo((info) => info.filter((user) => user.user !== caId));
    }
  };

  //  Handles the "Send Message" button click event. Shows an error if no employees are selected.
  const handleSendMessage = (): void => {
    if (selectedEmployees.length > 0) {
      dispatch(
        sendCallOnMessages({
          shiftId,
          callOutType: shiftDetails?.callOutType || NUMBER_0,
          users: userInfo,
        }),
      );
    } else {
      toast.toastError(Strings.Text.CallOutNotifyCheckBoxValidationText);
    }
  };

  // Handle the "Export as PDF" button click event.
  const handleExportPDFClick = (): void => {
    if (shiftId > NUMBER_0 && shiftFromTime && shiftDate) {
      dispatch(
        exportCallOutList({
          shiftId,
          shiftDate: shiftDate !== '-' ? shiftDate : '',
          shiftFromTime: shiftFromTime !== '-' ? shiftFromTime : '',
          currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET),
        }),
      );
    }
  };

  // Handle the "Select All" checkbox change event.
  const handleSelectAllChange = (isChecked: boolean) => {
    if (isChecked) {
      const allEmployeeIDs = callOnCAList ? callOnCAList.map((ca): ICA => ({ caId: ca.caId, ColorCode: ca.colorGroup, IsLD: ca.lightDuty, OnCall: ca.isOnCall })) : [];
      const allUsers = callOnCAList ? callOnCAList.map((ca): IcalloutUserInfo => ({ user: ca.caId, phone: ca.phone || '' })) : [];
      setSelectedEmployees(allEmployeeIDs);
      setUserInfo(allUsers);
    } else {
      setSelectedEmployees([]);
    }
    setIsSelectAllChecked(isChecked);
  };

  // Bind callout category.
  const bindCallOutCategory = (category: number) => {
    let categoryString: string | null = null;
    switch (category) {
      case NUMBER_1:
        categoryString = Strings.CalloutCategory.MorThanAShiftAway;
        break;
      case NUMBER_2:
        categoryString = Strings.CalloutCategory.LessThanAShiftAway;
        break;
      case NUMBER_3:
        categoryString = Strings.CalloutCategory.ninetyminAway;
        break;
      case NUMBER_4:
        categoryString = Strings.CalloutCategory.sixtyminAway;
        break;
      default:
        categoryString = Strings.CalloutCategory.MorThanAShiftAway;
        break;
    }
    return categoryString ? `(${categoryString})` : '';
  };

  return (
    <div>
      <div>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate({ pathname: AdminRoutePath.SCHCallOut }, { state: { calloutFilterType } })}>{Strings.HD.ManageCallOutShifts}</Breadcrumb.Item>
          <Breadcrumb.Item active>{Strings.Button.CAList}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="pageheader">
        <Row className="align-items-center">
          <Col>
            <h1>
              {Strings.HD.CAResourceOptions} <small>{bindCallOutCategory(shiftDetails?.callOutType || 0)}</small>
            </h1>
          </Col>
        </Row>
      </div>
      <AssignCACard caresource shiftDetails={shiftDetails} isLd={false} />
      <div className="mt-4">
        <h2>{shiftDetails?.callOutType === NUMBER_1 || shiftDetails?.callOutType === NUMBER_2 ? Strings.HD.CategoryAList : Strings.HD.CategoryBList}</h2>
      </div>
      <div className="filter-main mt-3">
        <Row className="justify-content-between align-items-center">
          <Col xs="auto">
            <KKICheckbox
              id="p1"
              key={2}
              name="publishStatus"
              checkBoxType="checkbox"
              label={Strings.Label.SelectAllCA}
              checked={isSelectAllChecked}
              onChange={(e: any) => handleSelectAllChange(e.target.checked)}
            />
          </Col>
          <Col sm="auto" xs={12} className="button-container-right text-end m-xs-16">
            <Button variant="outline-primary" type="submit" onClick={handleExportPDFClick}>
              <PDFIcon />
              {Strings.Button.ExportasPDF}
            </Button>
            <Button variant="primary" type="button" onClick={handleSendMessage}>
              {Strings.Button.SendMessage}
            </Button>
          </Col>
        </Row>
      </div>
      <Row>
        {callOnCAList &&
          callOnCAList.length > 0 &&
          callOnCAList.map((ca: ICADetails) => (
            <Col key={ca.caId} xl={6} lg={6} md={12}>
              <EmployeeCard
                caDetails={ca}
                shiftDetails={shiftDetails}
                isFrom={NavigationToEmployeeCardFrom.Callout_Page}
                selectedEmployees={selectedEmployees}
                isSelectAllChecked={isSelectAllChecked}
                onCheckboxChange={handleCheckboxChange}
              />
            </Col>
          ))}
      </Row>
      {(isShiftDetailsLoading || isFetchCallOnCALoading || isExportCallOutLoading || isSendCallOnSmsLoading) && <Loader />}
    </div>
  );
};
export default CAResource;
