/**
 * @file   src\containers\Scheduler\Schedule\CreateShift.tsx
 * @brief  This file is responsible for creating shift for CA.
 * @date   August, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import React from 'react';
import InputNumber from 'rc-input-number';
import { Button, Row, Col, Modal, ToggleButton, ToggleButtonGroup, useState, moment, useEffect } from '../../../components/ThirdPartyComponents';
import KKIDatepicker from '../../../components/KKIDatepicker';
import KKISelect from '../../../components/KKISelect';
import KKICheckbox from '../../../components/KKICheckbox';
import KKIInput from '../../../components/KKIInput';
import Strings from '../../../assests/strings/Strings.json';
import { IAddNewShiftRequest, ICreateShiftProps, IShiftTypes } from '../../../interfaces/scheduleInterface';
import { IObject } from '../../../interfaces/generalInterface';
import {
  DATE_END,
  DATE_FORMAT_HHMMA,
  DATE_FORMAT_MM_DD_YYYY,
  DATE_FORMAT_MM_DD_YYYY_FILTER,
  DATE_FORMAT_YYYY_MM_DD,
  DATE_START,
  DEAULT_TIME_FORMAT,
  NUMBER_0,
  NUMBER_1,
  NUMBER_2,
  NUMBER_3,
  NUMBER_4,
  NUMBER_5,
  NUMBER_6,
  NUMBER_7,
  STRING_0,
  TIME_FORMAT_HHMM,
} from '../../../utils/constants';
import { allowIntegerOnly } from '../../../utils/helper';
import { CreateShiftTypes } from '../../../utils/enums';
import { validateForm } from '../../../utils/validationHelper';
import { CREATE_SHIFT_SCHEMA } from '../../../validations/manageScheduleSchema';
import { addNewShift, addRecurringShift, editShift } from '../../../store/actions/manageAdminScheduleAction';
import { useAppDispatch } from '../../../hooks';
import { MessageToaster } from '../../../utils/toastUtils';

// Toast object creation.
const toast = new MessageToaster();
const CreateShift = ({ show, handleClose, shift, shiftTypeDetails, isEdit, shiftId, shiftDetails }: ICreateShiftProps) => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Initialize component state variables.
  const [shiftDays, setShiftDays] = useState<number[]>([]);
  const [selectedShift, setSelectedShift] = useState<IObject | null>(null);
  const [requiredCA, setRequiredCA] = useState<number>(NUMBER_0);
  const [requiredYellowGroup, setRequiredYellowgroup] = useState<string>(STRING_0);
  const [requiredBlueGroup, setRequiredBluegroup] = useState<string>(STRING_0);
  const [requiredRedGroup, setRequiredRedgroup] = useState<string>(STRING_0);
  const [requiredWhiteGroup, setRequiredWhitegroup] = useState<string>(STRING_0);
  const [requiredLDCount, setRequiredLDCount] = useState<string>(STRING_0);
  const [createShiftType, setCreateShiftType] = useState<number>(CreateShiftTypes.OneTime);
  const [shiftStartTime, setShiftStartTime] = useState<string>('');
  const [shiftEndTime, setShiftEndTime] = useState<string>('');
  const [shiftStartDate, setShiftStartDate] = useState<Date | null>(moment().toDate());
  const [shiftEndDate, setShiftEndDate] = useState<Date | null>(moment().toDate());
  const [duration, setDuration] = useState<moment.Duration | null>(null);
  const [errorFields, setErrorFields] = useState<IObject | null>(null);

  // Component initial loading.
  useEffect(() => {
    if (isEdit) {
      if (shiftDetails) {
        const shiftTypes = shift.find((item: any) => item.value === shiftDetails.shiftTypeId?.toString());
        setSelectedShift(shiftTypes ? ({ label: shiftTypes.label, value: shiftTypes.value } as IObject) : null);
        setRequiredCA(shiftDetails.totalCACount);
        setRequiredYellowgroup(shiftDetails.yellowRequired.toString());
        setRequiredBluegroup(shiftDetails.blueRequired.toString());
        setRequiredRedgroup(shiftDetails.redRequired.toString());
        setRequiredWhitegroup(shiftDetails.whiteRequired.toString());
        setRequiredLDCount(shiftDetails.lightDutyRequired.toString());
        setShiftStartDate(moment.utc(shiftDetails.shiftFromTime).toDate());
        setShiftEndDate(moment.utc(shiftDetails.shiftToTime).toDate());
        const startTime = shiftDetails?.shiftFromTime ? moment.utc(shiftDetails?.shiftFromTime).format(DATE_FORMAT_HHMMA) : '';
        const endTime = shiftDetails?.shiftToTime ? moment.utc(shiftDetails?.shiftToTime).format(DATE_FORMAT_HHMMA) : '';
        // Calculate the duration between the two dates
        const shiftDuration = moment.duration(moment(shiftDetails?.shiftToTime).diff(moment(shiftDetails?.shiftFromTime)));
        setShiftStartTime(startTime);
        setShiftEndTime(endTime);
        setDuration(shiftDuration);
      }
    }
  }, []);

  // Shift select change event handler.
  const onShiftSelect = async (val: IObject) => {
    const shiftData = shiftTypeDetails?.find((shiftInfo: IShiftTypes) => shiftInfo.shiftId === Number(val.value));
    const startTime = shiftData?.shifttimeFrom ? moment.utc(shiftData?.shifttimeFrom).format(DATE_FORMAT_HHMMA) : '';
    const endTime = shiftData?.shiftTimeTo ? moment.utc(shiftData?.shiftTimeTo).format(DATE_FORMAT_HHMMA) : '';
    // Calculate the duration between the two dates
    const shiftDuration = moment.duration(moment(shiftData?.shiftTimeTo).diff(moment(shiftData?.shifttimeFrom)));
    setDuration(shiftDuration);
    const shiftInfo = {
      shiftTypeId: Number(val?.value),
      shiftStartTime: startTime,
      shiftEndTime: endTime,
    };
    const errorresult = await validateForm(shiftInfo, CREATE_SHIFT_SCHEMA, errorFields);
    setErrorFields(errorresult);
    setShiftStartTime(startTime);
    setShiftEndTime(endTime);
    setSelectedShift(val);
  };

  // Required CA input change handler.
  const onRequiredCAChange = async (val: number | null) => {
    const shiftInfo = {
      requiredCA: Number(val),
    };
    const errorresult = await validateForm(shiftInfo, CREATE_SHIFT_SCHEMA, errorFields);
    setErrorFields(errorresult);
    setRequiredCA(Number(val));
  };

  // Required yellow colorgroup count input change event handler.
  const onRequiredYellowColorGroupChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const shiftInfo = {
      requiredYellow: value,
    };
    const errorresult = await validateForm(shiftInfo, CREATE_SHIFT_SCHEMA, errorFields);
    setErrorFields(errorresult);
    setRequiredYellowgroup(value);
  };

  // Required blue colorgroup count input change event handler.
  const onRequiredBlueColorGroupChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const shiftInfo = {
      requiredBlue: value,
    };
    const errorresult = await validateForm(shiftInfo, CREATE_SHIFT_SCHEMA, errorFields);
    setErrorFields(errorresult);
    setRequiredBluegroup(value);
  };

  // Required red colorgroup count input change event handler.
  const onRequiredRedColorGroupChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const shiftInfo = {
      requiredRed: value,
    };
    const errorresult = await validateForm(shiftInfo, CREATE_SHIFT_SCHEMA, errorFields);
    setErrorFields(errorresult);
    setRequiredRedgroup(value);
  };

  // Required white colorgroup count input change event handler.
  const onRequiredWhiteColorGroupChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const shiftInfo = {
      requiredWhite: value,
    };
    const errorresult = await validateForm(shiftInfo, CREATE_SHIFT_SCHEMA, errorFields);
    setErrorFields(errorresult);
    setRequiredWhitegroup(value);
  };

  // Required light duty count input change event handler.
  const onRequiredLDCountChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const shiftInfo = {
      requiredLD: value,
    };
    const errorresult = await validateForm(shiftInfo, CREATE_SHIFT_SCHEMA, errorFields);
    setErrorFields(errorresult);
    setRequiredLDCount(value);
  };

  // Create shift type radio button click event handler.
  const onCreateShiftTypeChange = (event: any) => {
    const { value } = event.target;
    setCreateShiftType(Number(value));
    if (Number(value) === CreateShiftTypes.OneTime) {
      setShiftDays([]);
      setShiftEndDate(moment().toDate());
    }
  };

  // Start/end date input select event handler.
  const handleDateChange = (date: Date | null, type: string) => {
    if (type === DATE_START) {
      setShiftStartDate(date);
      if (shiftEndDate && date && date > shiftEndDate) {
        setShiftEndDate(date);
      }
    } else {
      setShiftEndDate(date);
    }
  };

  // Shift days select event handler.
  const shiftDaysHandleChange = (val: any) => {
    setShiftDays(val);
  };

  // Clear button click handler.
  const onClearHandler = () => {
    setSelectedShift(null);
    setRequiredCA(NUMBER_0);
    setRequiredYellowgroup('');
    setRequiredBluegroup('');
    setRequiredRedgroup('');
    setRequiredWhitegroup('');
    setRequiredLDCount('');
    setShiftStartTime('');
    setShiftEndTime('');
    setShiftStartDate(moment().toDate());
    setShiftEndDate(moment().toDate());
    setCreateShiftType(CreateShiftTypes.OneTime);
    setShiftDays([]);
    setErrorFields(null);
  };

  // Get ca matching count comparing to yellow, blue, red and ld count.
  const isCACountMatched = () => {
    const totalGroups = Number(requiredYellowGroup) + Number(requiredRedGroup) + Number(requiredBlueGroup) + Number(requiredWhiteGroup) + Number(requiredLDCount);
    return totalGroups === requiredCA;
  };

  const getDays = (startDate: Date, endDate: Date) => {
    const days: number[] = [];
    const start = moment(startDate);
    const end = moment(endDate).add(NUMBER_1, 'days');
    // Iterate through each day in the range
    while (start <= end && days.length <= NUMBER_7) {
      if (!days.includes(start.day())) {
        days.push(start.day());
      }
      start.add(NUMBER_1, 'days');
    }
    return days;
  };

  // Save shift details button click handler.
  const onSaveShift = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const shiftInfo = {
      shiftTypeId: selectedShift?.value ? Number(selectedShift.value) : NUMBER_0,
      requiredCA,
      requiredYellow: requiredYellowGroup,
      requiredBlue: requiredBlueGroup,
      requiredRed: requiredRedGroup,
      requiredLD: requiredLDCount,
      requiredWhite: requiredWhiteGroup,
      createShiftType: Number(createShiftType),
      shiftStartTime,
      shiftEndTime,
      shiftStartDate,
      shiftEndDate,
      shiftDays,
    };
    const errorresult = await validateForm(shiftInfo, CREATE_SHIFT_SCHEMA, errorFields);
    setErrorFields(errorresult);
    // Form validation error check.
    if (Object.keys(errorresult).length === 0) {
      if (!isCACountMatched()) {
        toast.toastError(Strings.Text.TotalCANotMatched);
        return;
      }

      // Set shift start and end date based on shift type date.
      const startDateTime = `${moment(shiftStartDate).format(DATE_FORMAT_YYYY_MM_DD)}T${moment(shiftStartTime, DATE_FORMAT_HHMMA).format(TIME_FORMAT_HHMM)}:00Z`;
      const dateTimeWithDuration = moment.utc(startDateTime).add(duration || NUMBER_0);
      const endDateTime = `${dateTimeWithDuration.format(DATE_FORMAT_YYYY_MM_DD)}T${dateTimeWithDuration.format(TIME_FORMAT_HHMM)}:00Z`;

      // Generate create shift api request.
      const shiftAddRequest: IAddNewShiftRequest = {
        shiftId: NUMBER_0,
        shiftTypeId: Number(selectedShift?.value),
        shiftName: selectedShift?.label || '',
        caCount: requiredCA,
        yelloCount: Number(requiredYellowGroup),
        blueCount: Number(requiredBlueGroup),
        redCount: Number(requiredRedGroup),
        whiteCount: Number(requiredWhiteGroup),
        lightDutyCount: Number(requiredLDCount),
        startTime: startDateTime,
        endTime: endDateTime,
        published: false,
      };

      // Check selected shift ctreate type is One time or recurring.
      if (createShiftType === CreateShiftTypes.OneTime) {
        shiftAddRequest.shiftId = isEdit ? shiftDetails?.shiftId || NUMBER_0 : NUMBER_0;
        shiftAddRequest.shiftDate = `${moment(shiftStartDate).format(DATE_FORMAT_YYYY_MM_DD)}${DEAULT_TIME_FORMAT}`;
        if (isEdit) {
          dispatch(editShift(shiftAddRequest));
        } else {
          dispatch(addNewShift(shiftAddRequest));
        }
      } else {
        const allDays = getDays(shiftStartDate || moment().toDate(), shiftEndDate || moment().toDate());
        if (!shiftDays.every((num) => allDays.includes(num))) {
          toast.toastError(Strings.Text.ShiftDaysNotAvailable);
          return;
        }
        shiftAddRequest.startDate = `${moment(shiftStartDate).format(DATE_FORMAT_YYYY_MM_DD)}${DEAULT_TIME_FORMAT}`;
        shiftAddRequest.endDate = `${moment(shiftEndDate).format(DATE_FORMAT_YYYY_MM_DD)}${DEAULT_TIME_FORMAT}`;
        shiftAddRequest.weekDays = shiftDays;
        dispatch(addRecurringShift(shiftAddRequest));
      }
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg" className="max-680">
      <form onSubmit={onSaveShift}>
        <Modal.Header closeButton>
          <Modal.Title>{shiftId && shiftId > NUMBER_0 ? Strings.Button.EditShift : Strings.Button.CreateShift}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <KKISelect
                id="shift"
                label={Strings.Label.Shift}
                name="shift"
                className="custom-select"
                placeholder={Strings.Sort.Select}
                searchvalue={false}
                options={shift}
                value={selectedShift}
                onSelectChange={onShiftSelect}
                alert={errorFields?.shiftTypeId || ''}
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <label htmlFor="caRequired" className="form-label">
                {Strings.Schedule.TotalCARequired}
              </label>
              <InputNumber id="caRequired" min={0} max={100} upHandler={<div>+</div>} downHandler={<div>-</div>} type="number" value={requiredCA} onChange={onRequiredCAChange} />
              {errorFields?.requiredCA && <span className="error">{errorFields?.requiredCA || ''}</span>}
            </Col>
          </Row>
          <Row className="mt-4">
            <label htmlFor="colorGrp" className="form-label">
              {Strings.Label.ColorGroup}
            </label>
            <Col id="colorGrp" lg={9} md={8} sm={12}>
              <div className="d-flex gap-3 border p-3 rounded">
                <Col>
                  <div className="mt-3">
                    <KKIInput
                      id="yellowCARequired"
                      label={Strings.Schedule.YellowRequired}
                      name="yellowCARequired"
                      type="text"
                      value={requiredYellowGroup}
                      maxLength={NUMBER_2}
                      onChange={onRequiredYellowColorGroupChange}
                      onKeyDown={allowIntegerOnly}
                      alert={errorFields?.requiredYellow || ''}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mt-3">
                    <KKIInput
                      id="blueCARequired"
                      label={Strings.Schedule.BlueRequired}
                      name="blueCARequired"
                      type="text"
                      value={requiredBlueGroup}
                      maxLength={NUMBER_2}
                      onChange={onRequiredBlueColorGroupChange}
                      onKeyDown={allowIntegerOnly}
                      alert={errorFields?.requiredBlue || ''}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mt-3">
                    <KKIInput
                      id="redCARequired"
                      label={Strings.Schedule.RedRequired}
                      name="redCARequired"
                      type="text"
                      value={requiredRedGroup}
                      maxLength={NUMBER_2}
                      onChange={onRequiredRedColorGroupChange}
                      onKeyDown={allowIntegerOnly}
                      alert={errorFields?.requiredRed || ''}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mt-3">
                    <KKIInput
                      id="whiteCARequired"
                      label={Strings.Schedule.WhiteRequired}
                      name="whiteCARequired"
                      type="text"
                      value={requiredWhiteGroup}
                      maxLength={NUMBER_2}
                      onChange={onRequiredWhiteColorGroupChange}
                      onKeyDown={allowIntegerOnly}
                      alert={errorFields?.requiredWhite || ''}
                    />
                  </div>
                </Col>
              </div>
            </Col>
            <Col>
              <div className="d-flex border p-3 rounded">
                <Col>
                  <div className="mt-3">
                    <KKIInput
                      id="lightDutyRequired"
                      label={Strings.Schedule.LDRequired}
                      name="lightDutyRequired"
                      type="text"
                      value={requiredLDCount}
                      maxLength={NUMBER_2}
                      onChange={onRequiredLDCountChange}
                      onKeyDown={allowIntegerOnly}
                      alert={errorFields?.requiredLD || ''}
                    />
                  </div>
                </Col>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={6} md={6} sm={12}>
              <KKIInput
                id="shiftStartTime"
                label={Strings.Label.ShiftStartTime}
                name="shiftStartTime"
                type="text"
                value={shiftStartTime}
                disabled
                alert={errorFields?.shiftStartTime || ''}
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <KKIInput
                id="shiftEndTime"
                label={Strings.Label.ShiftEndTime}
                value={shiftEndTime}
                name="shiftEndTime"
                type="text"
                disabled
                alert={errorFields?.shiftEndTime || ''}
              />
            </Col>
          </Row>
          <div className="d-flex gap-4 my-4">
            <KKICheckbox
              id="oneTimeShift"
              name="createdShiftType"
              checkBoxType="radio"
              label={Strings.Label.OneTime}
              value={CreateShiftTypes.OneTime}
              checked={createShiftType === CreateShiftTypes.OneTime}
              onChange={onCreateShiftTypeChange}
            />
            <KKICheckbox
              id="recurringShift"
              name="createdShiftType"
              checkBoxType="radio"
              label={Strings.Label.Recurring}
              value={CreateShiftTypes.Recurring}
              checked={createShiftType === CreateShiftTypes.Recurring}
              {...(!isEdit && { onChange: onCreateShiftTypeChange })}
              disabled={isEdit}
            />
          </div>
          <Row className="d-flex align-items-center mb-3">
            <Col xl={6} md={6} sm={12} className="calendar-mob-left">
              <KKIDatepicker
                id="shiftStartDate"
                name="shiftStartDate"
                placeholder={Strings.Label.StartDate}
                label={Strings.Label.ShiftStartDate}
                value={shiftStartDate}
                dateFormat={DATE_FORMAT_MM_DD_YYYY_FILTER}
                minDate={moment().toDate()}
                onChangeDatepicker={(date: any) => handleDateChange(date, DATE_START)}
                alert={errorFields?.shiftStartDate || ''}
              />
            </Col>
            <Col xl={6} md={6} sm={12} className="calendar-mob-right">
              {createShiftType === CreateShiftTypes.Recurring && (
                <KKIDatepicker
                  id="shiftEndDate"
                  name="shiftEndDate"
                  label={Strings.Label.ShiftEndDate}
                  placeholder={Strings.Label.EndDate}
                  value={shiftEndDate}
                  dateFormat={DATE_FORMAT_MM_DD_YYYY_FILTER}
                  minDate={shiftStartDate || moment().toDate()}
                  onChangeDatepicker={(date: any) => handleDateChange(date, DATE_END)}
                  alert={errorFields?.shiftEndDate || ''}
                />
              )}
            </Col>
          </Row>
          {createShiftType === CreateShiftTypes.Recurring && (
            <label htmlFor="shiftDays" className="form-label">
              {Strings.Label.SelectShiftDays}
            </label>
          )}
          {createShiftType === CreateShiftTypes.Recurring && (
            <Col id="shiftDays">
              <ToggleButtonGroup type="checkbox" value={shiftDays} className="shiftdays-toggle" onChange={shiftDaysHandleChange}>
                <ToggleButton id="Sunday" value={NUMBER_0}>
                  {Strings.Week.Sunday}
                </ToggleButton>
                <ToggleButton id="Monday" value={NUMBER_1}>
                  {Strings.Week.Monday}
                </ToggleButton>
                <ToggleButton id="Tuesday" value={NUMBER_2}>
                  {Strings.Week.Tuesday}
                </ToggleButton>
                <ToggleButton id="Wednesday" value={NUMBER_3}>
                  {Strings.Week.Wednesday}
                </ToggleButton>
                <ToggleButton id="Thursday" value={NUMBER_4}>
                  {Strings.Week.Thursday}
                </ToggleButton>
                <ToggleButton id="Friday" value={NUMBER_5}>
                  {Strings.Week.Friday}
                </ToggleButton>
                <ToggleButton id="Saturday" value={NUMBER_6}>
                  {Strings.Week.Saturday}
                </ToggleButton>
              </ToggleButtonGroup>
              {errorFields?.shiftDays && <span className="error">{errorFields?.shiftDays}</span>}
            </Col>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Button variant="outline-primary" onClick={handleClose}>
            {Strings.Button.Cancel}
          </Button>
          <Button variant="outline-primary" onClick={onClearHandler}>
            {Strings.Button.Clear}
          </Button>
          <Button variant="primary" type="submit">
            {Strings.Button.Submit}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CreateShift;
