/**
 * @file   src\store\actions\commonAction.ts
 * @brief  This file is responsible for creating asynchronous api call for common functionalities.
 * @date   Sep, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/apiConfig';
import { CommonApi, KKIActionTypes } from '../../utils/apiUrls';
import { buildQueryString } from '../../utils/helper';
import { IResetPasswordRequest, IForgotPasswordOtpSendRequest, IVerifyOtpRequest, IChangePasswordRequest, IIsHolidayRequest } from '../../interfaces/commonInterface';
import { IGetCAShiftsAndLeaveDetailsRequest } from '../../interfaces/scheduleInterface';
import { QUERY_STRING_ARRAY_FORMAT } from '../../utils/constants';

// Otp verification api call.
export const verifyOtp = createAsyncThunk('/common/verifyOtp', async (request: IVerifyOtpRequest, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${CommonApi.VERIFY_OTP}?${buildQueryString(request)}`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Send otp api call.
export const sendOtp = createAsyncThunk('/common/sendOtp', async (validateRequest: IForgotPasswordOtpSendRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${CommonApi.SEND_OTP}?${buildQueryString(validateRequest)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Forgot password api call.
export const forgotPassword = createAsyncThunk('/common/forgotPassword', async (validateRequest: IForgotPasswordOtpSendRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${CommonApi.SEND_FORGOT_PASSWORD_OTP}?${buildQueryString(validateRequest)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Reset password api call.
export const resetPassword = createAsyncThunk('/common/resetPassword', async (resetRequest: IResetPasswordRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(CommonApi.RESET_PASSWORD, resetRequest);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Change password api call.
export const changePassword = createAsyncThunk('/common/changePassword', async (updateRequest: IChangePasswordRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(CommonApi.CHANGE_PASSWORD, updateRequest);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get user schedules and leave details api call.
export const getSchedulesAndLeaveDetails = createAsyncThunk('/common/getSchedulesAndLeaveDetails', async (request: IGetCAShiftsAndLeaveDetailsRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${CommonApi.GET_SCHEDULES_AND_LEAVE_DETAILS}?${buildQueryString(request, QUERY_STRING_ARRAY_FORMAT)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Forgot password resend otp api call.
export const forgotPasswordOtpResend = createAsyncThunk('/common/forgotPasswordOtpResend', async (validateRequest: IForgotPasswordOtpSendRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${CommonApi.SEND_FORGOT_PASSWORD_OTP}?${buildQueryString(validateRequest)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Check shift date is a holiday api call.
export const isShiftDateISHoliday = createAsyncThunk('/common/isShiftDateISHoliday', async (request: IIsHolidayRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(CommonApi.ISSHIFT_HOLIDAY, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get callout reason list api call.
export const getCalloutReasons = createAsyncThunk('/common/getCalloutReasons', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(CommonApi.GET_CALLOUT_REASONS);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Clear all common states to initial sate action.
export const revertAllCommonState = createAction(KKIActionTypes.REVERT_ALL_COMMON_STATE);
