/**
 * @file   src\store\slices\manageAdminScheduleSlice.ts
 * @brief  This file is responsible for creating admin(scheduler and manager) manage schedule slices to call actions and state management.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { IManageAdminScheduleState } from '../../interfaces/scheduleInterface';
import { getAlertMessage } from '../../utils/helper';
import {
  addNewShift,
  addRecurringShift,
  deleteShift,
  editShift,
  getAllCAScheduleAndLeaveDetails,
  getAllShiftsWithSummary,
  getAssignedOrUnassignedCAList,
  nofifyCA,
  publishShift,
  revertAllAdminScheduleState,
  updateNoshowAndOncall,
  unpublishShift,
} from '../actions/manageAdminScheduleAction';

// Initial state.
const shiftsDefaultState: IManageAdminScheduleState = {
  isAddNewShiftSuccess: false,
  isAddNewShiftLoading: false,
  addNewShiftErrorCode: 0,
  addNewShiftMessage: null,

  isGetAllShiftWithSummarySuccess: false,
  isGetAllShiftWithSummaryLoading: false,
  getAllShiftWithSummaryErrorCode: 0,
  getAllShiftWithSummaryMessage: null,
  shiftListWithSummary: null,

  isCaListSuccess: false,
  isCaListLoading: false,
  caListErrorCode: 0,
  caListMessage: null,
  caList: null,

  isPublishShiftSuccess: false,
  isPublishShiftLoading: false,
  publishShiftErrorCode: 0,
  publishShiftMessage: null,

  isUnPublishShiftSuccess: false,
  isUnPublishShiftLoading: false,
  unPublishShiftErrorCode: 0,
  unPublishShiftMessage: null,

  isDeleteShiftSuccess: false,
  isDeleteShiftLoading: false,
  deleteShiftErrorCode: 0,
  deleteShiftMessage: null,

  isCAShiftAndLeaveDetailsSuccess: false,
  isCAShiftAndLeaveDetailsLoading: false,
  caShiftAndLeaveDetailsErrorCode: 0,
  caShiftAndLeaveDetailsMessage: null,
  caShiftAndLeaveDetails: null,

  isEditShiftSuccess: false,
  isEditShiftLoading: false,
  editShiftErrorCode: 0,
  editShiftMessage: null,

  isUpdateNoshowAndOncallSuccess: false,
  isUpdateNoshowAndOncallLoading: false,
  updateNoshowAndOncallErrorCode: 0,
  updateNoshowAndOncallMessage: null,

  isNotifyCASuccess: false,
  isNotifyCALoading: false,
  notifyCAErrorCode: 0,
  notifyCAMessage: null,
};

// Manage admin schedule slice
const manageAdminScheduleSlice = createSlice({
  name: 'manageAdminSchedule',
  initialState: shiftsDefaultState,
  reducers: {
    resetShiftsDefaultState: () => shiftsDefaultState,
  },
  extraReducers(builder) {
    // ********************* Start of add new shift action state handling. *********************
    // Add shift default state.
    builder.addCase(addNewShift.pending, (state) => {
      state.isAddNewShiftLoading = true;
      state.isAddNewShiftSuccess = false;
      state.addNewShiftErrorCode = 0;
      state.addNewShiftMessage = '';
    });
    // Add shift success state.
    builder.addCase(addNewShift.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isAddNewShiftLoading = false;
      state.isAddNewShiftSuccess = true;
      state.addNewShiftErrorCode = code;
      state.addNewShiftMessage = status || '';
    });
    // Add shift error state.
    builder.addCase(addNewShift.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isAddNewShiftLoading = false;
      state.isAddNewShiftSuccess = false;
      state.addNewShiftErrorCode = errcode;
      state.addNewShiftMessage = error || '';
    });

    // ********************* End of add new shift action state handling. *********************

    // ********************* Start of all shifts with summary action state handling. *********************
    // Get all shifts with summary default state.
    builder.addCase(getAllShiftsWithSummary.pending, (state) => {
      state.isGetAllShiftWithSummaryLoading = true;
      state.isGetAllShiftWithSummarySuccess = false;
      state.getAllShiftWithSummaryErrorCode = 0;
      state.getAllShiftWithSummaryMessage = '';
    });
    // Get all shifts with summary success state.
    builder.addCase(getAllShiftsWithSummary.fulfilled, (state, action) => {
      const data = action.payload.info || null;
      const { code, status } = action.payload;
      state.isGetAllShiftWithSummaryLoading = false;
      state.isGetAllShiftWithSummarySuccess = true;
      state.shiftListWithSummary = data || null;
      state.getAllShiftWithSummaryErrorCode = code;
      state.getAllShiftWithSummaryMessage = status || '';
    });
    // Get all shifts with summary error state.
    builder.addCase(getAllShiftsWithSummary.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isGetAllShiftWithSummaryLoading = false;
      state.isGetAllShiftWithSummarySuccess = false;
      state.getAllShiftWithSummaryErrorCode = errcode;
      state.getAllShiftWithSummaryMessage = error || '';
    });

    // ********************* End of all shifts with summary action state handling. *********************

    // ********************* Start of assigned/unassigned ca list action state handling. *********************

    // Assigned/unassigned ca list default state.
    builder.addCase(getAssignedOrUnassignedCAList.pending, (state) => {
      state.isCaListLoading = true;
      state.isCaListSuccess = false;
      state.caListErrorCode = 0;
      state.caListMessage = '';
    });
    // Assigned/unassigned ca list success state.
    builder.addCase(getAssignedOrUnassignedCAList.fulfilled, (state, action) => {
      const { code, status, info } = action.payload;
      state.isCaListLoading = false;
      state.isCaListSuccess = true;
      state.caList = info;
      state.caListErrorCode = code;
      state.caListMessage = status || '';
    });
    // Assigned/unassigned ca list error state.
    builder.addCase(getAssignedOrUnassignedCAList.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isCaListLoading = false;
      state.isCaListSuccess = false;
      state.caListErrorCode = errcode;
      state.caListMessage = error || '';
    });

    // ********************* End of assigned/unassigned ca list action state handling. *********************

    // ********************* Start of publish shift action state handling. *********************

    // Publish shift default state.
    builder.addCase(publishShift.pending, (state) => {
      state.isPublishShiftLoading = true;
      state.isPublishShiftSuccess = false;
      state.publishShiftErrorCode = 0;
      state.publishShiftMessage = '';
    });
    //  Publish shift success state.
    builder.addCase(publishShift.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isPublishShiftLoading = false;
      state.isPublishShiftSuccess = true;
      state.publishShiftErrorCode = code;
      state.publishShiftMessage = status || '';
    });
    // Publish shift error state.
    builder.addCase(publishShift.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isPublishShiftLoading = false;
      state.isPublishShiftSuccess = false;
      state.publishShiftErrorCode = errcode;
      state.publishShiftMessage = error || '';
    });

    // ********************* End of publish shift action state handling. *********************

    // ********************* Start of unpublish shift action state handling. *********************

    // Unpublish shift default state.
    builder.addCase(unpublishShift.pending, (state) => {
      state.isUnPublishShiftLoading = true;
      state.isUnPublishShiftSuccess = false;
      state.unPublishShiftErrorCode = 0;
      state.unPublishShiftMessage = '';
    });
    //  Unpublish shift success state.
    builder.addCase(unpublishShift.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isUnPublishShiftLoading = false;
      state.isUnPublishShiftSuccess = true;
      state.unPublishShiftErrorCode = code;
      state.unPublishShiftMessage = status || '';
    });
    // Unpublish shift error state.
    builder.addCase(unpublishShift.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isUnPublishShiftLoading = false;
      state.isUnPublishShiftSuccess = false;
      state.unPublishShiftErrorCode = errcode;
      state.unPublishShiftMessage = error || '';
    });

    // ********************* End of unpublish shift action state handling. *********************

    // ********************* Start of delete shift action state handling. *********************

    // Delete shift default state.
    builder.addCase(deleteShift.pending, (state) => {
      state.isDeleteShiftLoading = true;
      state.isDeleteShiftSuccess = false;
      state.deleteShiftErrorCode = 0;
      state.deleteShiftMessage = '';
    });
    //  Delete shift success state.
    builder.addCase(deleteShift.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isDeleteShiftLoading = false;
      state.isDeleteShiftSuccess = true;
      state.deleteShiftErrorCode = code;
      state.deleteShiftMessage = status || '';
    });
    // Delete shift error state.
    builder.addCase(deleteShift.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isDeleteShiftLoading = false;
      state.isDeleteShiftSuccess = false;
      state.deleteShiftErrorCode = errcode;
      state.deleteShiftMessage = error || '';
    });

    // ********************* End of delete shift action state handling. *********************

    // ********************* Start of get all CA/given CA with their schedule and leave by filter action state handling. *********************

    // Get all CA/given CA with their schedule and leave by filter default state.
    builder.addCase(getAllCAScheduleAndLeaveDetails.pending, (state) => {
      state.isCAShiftAndLeaveDetailsLoading = true;
      state.isCAShiftAndLeaveDetailsSuccess = false;
      state.caShiftAndLeaveDetailsErrorCode = 0;
      state.caShiftAndLeaveDetailsMessage = '';
    });
    // Get all CA/given CA with their schedule and leave by filter success state.
    builder.addCase(getAllCAScheduleAndLeaveDetails.fulfilled, (state, action) => {
      const error = action.payload?.error || 0;
      const data = action.payload?.data || {};
      state.isCAShiftAndLeaveDetailsLoading = false;
      state.isCAShiftAndLeaveDetailsSuccess = true;
      state.caShiftAndLeaveDetailsErrorCode = error;
      state.caShiftAndLeaveDetailsMessage = getAlertMessage(error !== 0 ? error.toString() : '');
      state.caShiftAndLeaveDetails = data || [];
    });
    // Get all CA/given CA with their schedule and leave by filter error state.
    builder.addCase(getAllCAScheduleAndLeaveDetails.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.isCAShiftAndLeaveDetailsLoading = false;
      state.isCAShiftAndLeaveDetailsSuccess = false;
      state.caShiftAndLeaveDetailsErrorCode = error;
      state.caShiftAndLeaveDetailsMessage = getAlertMessage(error !== 0 ? error.toString() : '');
    });

    // ********************* End of get all CA/given CA with their schedule and leave by filter action state handling. *********************

    // ********************* Start of add recurring shift action state handling. *********************
    // Add recurring shift default state.
    builder.addCase(addRecurringShift.pending, (state) => {
      state.isAddNewShiftLoading = true;
      state.isAddNewShiftSuccess = false;
      state.addNewShiftErrorCode = 0;
      state.addNewShiftMessage = '';
    });
    // Add recurring shift success state.
    builder.addCase(addRecurringShift.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isAddNewShiftLoading = false;
      state.isAddNewShiftSuccess = true;
      state.addNewShiftErrorCode = code;
      state.addNewShiftMessage = status || '';
    });
    // Add recurring shift error state.
    builder.addCase(addRecurringShift.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isAddNewShiftLoading = false;
      state.isAddNewShiftSuccess = false;
      state.addNewShiftErrorCode = errcode;
      state.addNewShiftMessage = error || '';
    });
    // ********************* End of add recurring shift action state handling. *********************

    // ********************* Start of edit shift action state handling. *********************
    // Edit shift default state.
    builder.addCase(editShift.pending, (state) => {
      state.isEditShiftLoading = true;
      state.isEditShiftSuccess = false;
      state.editShiftErrorCode = 0;
      state.editShiftMessage = '';
    });
    // Edit shift success state.
    builder.addCase(editShift.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isEditShiftLoading = false;
      state.isEditShiftSuccess = true;
      state.editShiftErrorCode = code;
      state.editShiftMessage = status || '';
    });
    // Edit shift error state.
    builder.addCase(editShift.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isEditShiftLoading = false;
      state.isEditShiftSuccess = false;
      state.editShiftErrorCode = errcode;
      state.editShiftMessage = error || '';
    });

    // ********************* End of edit shift action state handling. *********************

    // ********************* Start of update noshow and oncall action state handling. *********************
    // Update noshow and oncall default state.
    builder.addCase(updateNoshowAndOncall.pending, (state) => {
      state.isUpdateNoshowAndOncallLoading = true;
      state.isUpdateNoshowAndOncallSuccess = false;
      state.updateNoshowAndOncallErrorCode = 0;
      state.updateNoshowAndOncallMessage = '';
    });
    // Update noshow and oncall success state.
    builder.addCase(updateNoshowAndOncall.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isUpdateNoshowAndOncallLoading = false;
      state.isUpdateNoshowAndOncallSuccess = true;
      state.updateNoshowAndOncallErrorCode = code;
      state.updateNoshowAndOncallMessage = status || '';
    });
    // Update noshow and oncall error state.
    builder.addCase(updateNoshowAndOncall.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isUpdateNoshowAndOncallLoading = false;
      state.isUpdateNoshowAndOncallSuccess = false;
      state.updateNoshowAndOncallErrorCode = errcode;
      state.updateNoshowAndOncallMessage = error || '';
    });

    // ********************* End of update noshow and oncall action state handling. *********************

    // ********************* Start of notify CA action state handling. *********************
    // Notify CA default state.
    builder.addCase(nofifyCA.pending, (state) => {
      state.isNotifyCALoading = true;
      state.isNotifyCASuccess = false;
      state.notifyCAErrorCode = 0;
      state.notifyCAMessage = '';
    });
    // Notify CA success state.
    builder.addCase(nofifyCA.fulfilled, (state, action) => {
      const { code, status } = action.payload;
      state.isNotifyCALoading = false;
      state.isNotifyCASuccess = true;
      state.notifyCAErrorCode = code;
      state.notifyCAMessage = status || '';
    });
    // Notify CA error state.
    builder.addCase(nofifyCA.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isNotifyCALoading = false;
      state.isNotifyCASuccess = false;
      state.notifyCAErrorCode = errcode;
      state.notifyCAMessage = error || '';
    });

    // ********************* End of notify CA action state handling. *********************

    // ********************* Clear all admin schedule states to initial state action. *********************
    builder.addCase(revertAllAdminScheduleState, (state) => {
      state.isAddNewShiftSuccess = false;
      state.isAddNewShiftLoading = false;
      state.addNewShiftErrorCode = 0;
      state.addNewShiftMessage = null;
      state.isGetAllShiftWithSummarySuccess = false;
      state.isGetAllShiftWithSummaryLoading = false;
      state.getAllShiftWithSummaryErrorCode = 0;
      state.getAllShiftWithSummaryMessage = null;
      state.isCaListSuccess = false;
      state.isCaListLoading = false;
      state.caListErrorCode = 0;
      state.caListMessage = null;
      state.isPublishShiftSuccess = false;
      state.isPublishShiftLoading = false;
      state.publishShiftErrorCode = 0;
      state.publishShiftMessage = null;
      state.isUnPublishShiftSuccess = false;
      state.isUnPublishShiftLoading = false;
      state.unPublishShiftErrorCode = 0;
      state.unPublishShiftMessage = null;
      state.isDeleteShiftSuccess = false;
      state.isDeleteShiftLoading = false;
      state.deleteShiftErrorCode = 0;
      state.deleteShiftMessage = null;
      state.isCAShiftAndLeaveDetailsSuccess = false;
      state.isCAShiftAndLeaveDetailsLoading = false;
      state.caShiftAndLeaveDetailsErrorCode = 0;
      state.caShiftAndLeaveDetailsMessage = null;
      state.isEditShiftSuccess = false;
      state.isEditShiftLoading = false;
      state.editShiftErrorCode = 0;
      state.editShiftMessage = null;
      state.isUpdateNoshowAndOncallSuccess = false;
      state.isUpdateNoshowAndOncallLoading = false;
      state.updateNoshowAndOncallErrorCode = 0;
      state.updateNoshowAndOncallMessage = null;
      state.isNotifyCASuccess = false;
      state.isNotifyCALoading = false;
      state.notifyCAErrorCode = 0;
      state.notifyCAMessage = null;
    });
  },
});

// Export manage shift actions.
export const { resetShiftsDefaultState } = manageAdminScheduleSlice.actions;

// Export reducer.
export default manageAdminScheduleSlice.reducer;
