/**
 * @file   src\containers\Scheduler\Schedule\ShiftDetails.tsx
 * @brief  Scheduler shift details page
 * @date   August, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { CURRENT_DATE_FORMAT_WITH_OFFSET, HTTP_STATUS_200, NUMBER_0, NUMBER_1, NUMBER_EMPTY, OTHER_REASON_LABEL } from '../../../utils/constants';
import { RootState } from '../../../store';
import Loader from '../../../components/Loader';
import { Button, Row, Col, Breadcrumb, useState, useParams, Modal, useEffect, useNavigate, moment } from '../../../components/ThirdPartyComponents';
import CreateShiftComponent from './CreateShift';
import DeleteIcon from '../../../assests/icons/Delete';
import EditIcon from '../../../assests/icons/Edit';
import ShiftDetailsCard from '../../../components/ShiftDetailsCard';
import EmployeeCard from '../../../components/EmployeeCard';
import {
  ISelectOptions,
  IShiftTypes,
  IShiftDetailsRequest,
  IAssignedOrUnassigedCAListRequest,
  IUnassignCARequest,
  ICallOutShiftRequest,
  INoshowRequestInterface,
  IOncallRequestInterface,
} from '../../../interfaces/scheduleInterface';
import { MyScheduleActions, ScheduleTabs, AdminRoutePath, NavigationToEmployeeCardFrom } from '../../../utils/enums';
import Strings from '../../../assests/strings/Strings.json';
import { MessageToaster } from '../../../utils/toastUtils';
import { callOutShift, getAllShiftTypes, getShiftDetails, revertAllScheduleState, unassignCA } from '../../../store/actions/manageCAScheduleAction';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  deleteShift,
  getAssignedOrUnassignedCAList,
  publishShift,
  revertAllAdminScheduleState,
  unpublishShift,
  updateNoshowAndOncall,
} from '../../../store/actions/manageAdminScheduleAction';
import { ICADetails } from '../../../interfaces/caInterface';
import KKISelect from '../../../components/KKISelect';
import KKIInput from '../../../components/KKIInput';
import { IObject } from '../../../interfaces/generalInterface';
import { ICalloutReasons } from '../../../interfaces/commonInterface';
import { validateForm } from '../../../utils/validationHelper';
import { CALLOUT_REASON_SCHEMA } from '../../../validations/manageScheduleSchema';
import { getCalloutReasons, revertAllCommonState } from '../../../store/actions/commonAction';

// Toast object creation.
const toast = new MessageToaster();

const ShiftDetails = () => {
  // Declare action dispatch.
  const dispatch = useAppDispatch();

  // Create route param object to access the route parameters.
  const params = useParams();

  // Navigate object creation.
  const navigate = useNavigate();

  // Access redux state variables.
  const {
    isShiftDetailsLoading,
    shiftDetails,
    isShiftTypesSuccess,
    isShiftTypesLoading,
    shiftTypes,
    isUnassignShiftLoading,
    isUnassignShiftSuccess,
    unassignShiftErrorCode,
    unassignShiftMessage,
    isCallOutShiftLoading,
    isCallOutShiftSuccess,
    callOutShiftErrorCode,
    callOutShiftMessage,
  } = useAppSelector((state: RootState) => state.manageCASchedule);
  const {
    isPublishShiftLoading,
    isPublishShiftSuccess,
    publishShiftErrorCode,
    publishShiftMessage,
    isDeleteShiftLoading,
    isDeleteShiftSuccess,
    deleteShiftErrorCode,
    deleteShiftMessage,
    isEditShiftLoading,
    isEditShiftSuccess,
    editShiftErrorCode,
    editShiftMessage,
    isCaListSuccess,
    caListErrorCode,
    isCaListLoading,
    caList,
    isUpdateNoshowAndOncallSuccess,
    isUpdateNoshowAndOncallLoading,
    updateNoshowAndOncallErrorCode,
    updateNoshowAndOncallMessage,
    isUnPublishShiftLoading,
    isUnPublishShiftSuccess,
    unPublishShiftErrorCode,
    unPublishShiftMessage,
  } = useAppSelector((state: RootState) => state.manageAdminSchedule);

  const { isFetchCalloutReasonSuccess, fetchCalloutReasonErrorCode, fetchCalloutReasonMessage, callOutReasons } = useAppSelector((state: RootState) => state.common);

  // Initialize component stat variables.
  const [publishShiftPopup, showPublishShiftPopup] = useState<boolean>(false);
  const [unpublishShiftPopup, showUnPublishShiftPopup] = useState<boolean>(false);
  const [unAssignPopup, showUnAssignPopup] = useState<boolean>(false);
  const [callOutPopup, showCallOutPopup] = useState<boolean>(false);
  const [deleteShiftPopup, showDeleteShiftPopup] = useState<boolean>(false);
  const [saveButtonPopup, showSaveButtonPopup] = useState<boolean>(false);
  const [createShiftPopup, showCreateShiftPopup] = useState(false);
  const [shiftTypeDetails, setShiftTypeDetails] = useState<IShiftTypes[]>([]);
  const [shifts, setShifts] = useState<ISelectOptions[]>([]);
  const [shiftDetailsRequestParams, setShiftDetailsRequestParams] = useState<IShiftDetailsRequest | null>(null);
  const [assignedCAList, setAssignedCAList] = useState<ICADetails[]>([]);
  const [unAssignRequest, setUnAssignRequest] = useState<IUnassignCARequest | null>(null);
  const [callOutShiftRequest, setCallOutShiftRequest] = useState<ICallOutShiftRequest | null>(null);
  const [onCalls, setOnCalls] = useState<IOncallRequestInterface[]>([]);
  const [noShows, setNoShows] = useState<INoshowRequestInterface[]>([]);
  const [callOutReasonList, setCallOutReasonList] = useState<IObject[]>([]);
  const [selectedReason, setSelectedReason] = useState<IObject | null>(null);
  const [callOutReasonText, setCallOutReasonText] = useState<string>('');
  const [errorFields, setErrorFields] = useState<IObject | null>(null);

  // Component initial loading.
  useEffect(() => {
    /* Access and set the location url parameters. */
    const shiftId: number = params.shiftId ? Number(params.shiftId) : 0;
    const caId: number = params.caId ? Number(params.caId) : 0;
    const type: number = params.shiftId ? Number(params.type) : 0;
    const requestParams =
      type === ScheduleTabs.MySchedule
        ? { userId: caId, shiftId, currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET) }
        : { shiftId, currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET) };
    setShiftDetailsRequestParams(requestParams);
    dispatch(getShiftDetails(requestParams));
  }, [
    isPublishShiftSuccess && publishShiftErrorCode === HTTP_STATUS_200,
    isUnPublishShiftSuccess && unPublishShiftErrorCode === HTTP_STATUS_200,
    isUnassignShiftSuccess && unassignShiftErrorCode === HTTP_STATUS_200,
    isCallOutShiftSuccess && callOutShiftErrorCode === HTTP_STATUS_200,
    isUpdateNoshowAndOncallSuccess && updateNoshowAndOncallErrorCode === HTTP_STATUS_200,
  ]);

  // Method to get assigned CA list
  const getCAList = () => {
    if (shiftDetails?.shiftId) {
      const requestParams: IAssignedOrUnassigedCAListRequest = {
        shiftId: shiftDetails?.shiftId,
        type: NUMBER_1,
      };
      dispatch(getAssignedOrUnassignedCAList(requestParams));
    }
  };

  useEffect(() => {
    getCAList(); // Call the assigned CA List Api
  }, [shiftDetails]);
  // Component initial loading for fetching shift types.
  useEffect(() => {
    dispatch(getAllShiftTypes());
    // On unmount reset states
    return () => {
      dispatch(revertAllAdminScheduleState());
      dispatch(revertAllScheduleState());
    };
  }, []);

  // Shift types state change side effect handling.
  useEffect(() => {
    if (isShiftTypesSuccess && shiftTypes && shiftTypes.length > 0) {
      const shiftTypeOptions = shiftTypes.map(
        (shiftType: IShiftTypes): ISelectOptions => ({ label: `${shiftType.shiftName} (${shiftType.shiftShortName})`, value: shiftType.shiftId.toString() }),
      );
      setShiftTypeDetails(shiftTypes);
      setShifts(shiftTypeOptions);
    }
  }, [isShiftTypesSuccess, shiftTypes]);

  // Callout reasons api response state change.
  useEffect(() => {
    if (isFetchCalloutReasonSuccess && fetchCalloutReasonErrorCode === HTTP_STATUS_200) {
      if (callOutReasons && callOutReasons.length > 0) {
        const reasonOptions = callOutReasons.map((leave: ICalloutReasons): IObject => ({ label: leave.reason, value: leave.reasonId.toString() }));
        setCallOutReasonList(reasonOptions);
        setSelectedReason(reasonOptions[0] ?? null);
      }
    } else if (!isFetchCalloutReasonSuccess && fetchCalloutReasonErrorCode > HTTP_STATUS_200 && fetchCalloutReasonMessage) {
      toast.toastError(fetchCalloutReasonMessage);
      dispatch(revertAllCommonState());
    }
  }, [isFetchCalloutReasonSuccess, fetchCalloutReasonErrorCode, fetchCalloutReasonMessage, callOutReasons]);

  // Assigend CA state change side effect handling.
  useEffect(() => {
    if (isCaListSuccess && caListErrorCode === HTTP_STATUS_200 && caList) {
      setAssignedCAList(caList?.caList);
      setOnCalls([]);
      setNoShows([]);
    }
  }, [isCaListSuccess, caListErrorCode, caList]);

  // Publish shift api response state change.
  useEffect(() => {
    if (isPublishShiftSuccess && publishShiftErrorCode === HTTP_STATUS_200 && publishShiftMessage) {
      toast.toastSuccess(publishShiftMessage);
    } else if (!isPublishShiftSuccess && publishShiftErrorCode > HTTP_STATUS_200 && publishShiftMessage) {
      toast.toastError(publishShiftMessage);
    }
  }, [isPublishShiftSuccess, publishShiftErrorCode, publishShiftMessage]);

  // Unpublish shift api response state change.
  useEffect(() => {
    if (isUnPublishShiftSuccess && unPublishShiftErrorCode === HTTP_STATUS_200 && unPublishShiftMessage) {
      toast.toastSuccess(unPublishShiftMessage);
    } else if (!isUnPublishShiftSuccess && unPublishShiftErrorCode > HTTP_STATUS_200 && unPublishShiftMessage) {
      toast.toastError(unPublishShiftMessage);
    }
  }, [isUnPublishShiftSuccess, unPublishShiftErrorCode, unPublishShiftMessage]);

  // Delete shift api response state change.
  useEffect(() => {
    if (isDeleteShiftSuccess && deleteShiftErrorCode === HTTP_STATUS_200 && deleteShiftMessage) {
      toast.toastSuccess(deleteShiftMessage);
      navigate(AdminRoutePath.ManageSchedule);
    } else if (!isDeleteShiftSuccess && deleteShiftErrorCode > HTTP_STATUS_200 && deleteShiftMessage) {
      toast.toastError(deleteShiftMessage);
    }
  }, [isDeleteShiftSuccess, deleteShiftErrorCode, deleteShiftMessage]);
  // Unassign shift api response state change.
  useEffect(() => {
    if (isEditShiftSuccess && editShiftErrorCode === HTTP_STATUS_200 && editShiftMessage) {
      toast.toastSuccess(editShiftMessage);
      if (shiftDetailsRequestParams) {
        shiftDetailsRequestParams.currentTime = moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET);
        dispatch(getShiftDetails(shiftDetailsRequestParams));
      }
    } else if (!isEditShiftSuccess && editShiftErrorCode > HTTP_STATUS_200 && editShiftMessage) {
      toast.toastError(editShiftMessage);
    }
  }, [isEditShiftSuccess, editShiftErrorCode, editShiftMessage]);

  // Unassign shift api response state change.
  useEffect(() => {
    if (isUnassignShiftSuccess && unassignShiftErrorCode === HTTP_STATUS_200 && unassignShiftMessage) {
      toast.toastSuccess(unassignShiftMessage);
      getCAList();
    } else if (!isUnassignShiftSuccess && unassignShiftErrorCode > HTTP_STATUS_200 && unassignShiftMessage) {
      toast.toastError(unassignShiftMessage);
    }
  }, [isUnassignShiftSuccess, unassignShiftErrorCode, unassignShiftMessage]);

  // Callout shift api response state change.
  useEffect(() => {
    if (isCallOutShiftSuccess && callOutShiftErrorCode === HTTP_STATUS_200 && callOutShiftMessage) {
      toast.toastSuccess(callOutShiftMessage);
      getCAList();
    } else if (!isCallOutShiftSuccess && callOutShiftErrorCode > HTTP_STATUS_200 && callOutShiftMessage) {
      toast.toastError(callOutShiftMessage);
    }
  }, [isCallOutShiftSuccess, callOutShiftErrorCode, callOutShiftMessage]);

  // No show and oncall api response state change.
  useEffect(() => {
    if (isUpdateNoshowAndOncallSuccess && updateNoshowAndOncallErrorCode === HTTP_STATUS_200 && updateNoshowAndOncallMessage) {
      toast.toastSuccess(updateNoshowAndOncallMessage);
      getCAList();
    } else if (!isUpdateNoshowAndOncallSuccess && updateNoshowAndOncallErrorCode > HTTP_STATUS_200 && updateNoshowAndOncallMessage) {
      toast.toastError(updateNoshowAndOncallMessage);
    }
  }, [isUpdateNoshowAndOncallSuccess, updateNoshowAndOncallErrorCode, updateNoshowAndOncallMessage]);

  // Method to un-assign shift.
  const unAssignShift = (caId: number, shift: number, shiftStart: string) => {
    setUnAssignRequest((prev) => ({
      ...prev,
      caId,
      shift,
      shiftStart,
    }));
    showUnAssignPopup(true);
  };

  // Unassign button click handler.
  const onUnAssignClick = () => {
    if (unAssignRequest) {
      dispatch(unassignCA(unAssignRequest));
    }
    showUnAssignPopup(false);
  };

  // Method to call-out shift.
  const callOutCAFromShift = (caId: number, shift: number, shiftStart: string) => {
    dispatch(getCalloutReasons());
    setCallOutShiftRequest((prev) => ({
      ...prev,
      caId,
      shiftId: shift,
      shiftStart,
      currentTime: moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET),
      reasonId: NUMBER_0,
      description: '',
    }));
    setErrorFields(null);
    setCallOutReasonText('');
    showCallOutPopup(true);
  };

  // Callout button click handler.
  const onShiftCallOutClick = async () => {
    const callOutReasonForm = {
      callOutReasonType: selectedReason?.value ? Number(selectedReason.value) : NUMBER_EMPTY,
      reason: callOutReasonText,
    };
    const errorResult = await validateForm(callOutReasonForm, CALLOUT_REASON_SCHEMA, errorFields);
    setErrorFields(errorResult);
    if (callOutShiftRequest && Object.keys(errorResult).length === NUMBER_0) {
      const callOutRequest = { ...callOutShiftRequest };
      callOutRequest.currentTime = moment().format(CURRENT_DATE_FORMAT_WITH_OFFSET);
      callOutRequest.reasonId = selectedReason?.value ? Number(selectedReason.value) : NUMBER_0;
      callOutRequest.description = callOutReasonText;
      dispatch(callOutShift(callOutRequest));
      showCallOutPopup(false);
    }
  };

  // My schedule button click handler.
  const onScheduleButtonClick = (caId: number, type: number) => {
    const shiftDate = shiftDetails?.shiftFromTime;
    const shiftId = shiftDetails?.shiftId;
    if (shiftDate && shiftId) {
      if (type === MyScheduleActions.Unassign) {
        unAssignShift(caId, shiftId, shiftDate);
      } else if (type === MyScheduleActions.Callout) {
        callOutCAFromShift(caId, shiftId, shiftDate);
      }
    }
  };

  // Edit shift button click handler.
  const onShiftEditClickHandler = () => {
    if ((shiftDetails && shiftDetails?.assignedCACount > NUMBER_0) || (shiftDetails && shiftDetails?.onCallTotal > NUMBER_0)) {
      toast.toastError(Strings.Text.EditNotAllowed);
    } else {
      showCreateShiftPopup(true);
    }
  };

  // Delete shift click handler.
  const onShiftDeleteClickHandler = () => {
    if ((shiftDetails && shiftDetails?.assignedCACount > NUMBER_0) || (shiftDetails && shiftDetails?.onCallTotal > NUMBER_0)) {
      toast.toastError(Strings.Text.DeleteNotAllowed);
    } else {
      showDeleteShiftPopup(true);
    }
  };

  //  Handles the "Save" button click event.
  const handleSaveButtonClick = (): void => {
    showSaveButtonPopup(true);
  };

  // Callout reason type dropdown select change handler.
  const onCalloutReasonSelect = (val: IObject) => {
    setSelectedReason(val);
  };

  // Callout reason input change handler.
  const onCalloutReasonChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const callOutReasonForm = {
      callOutReasonType: selectedReason?.value ? Number(selectedReason.value) : NUMBER_EMPTY,
      reason: value,
    };
    const errorResult = await validateForm(callOutReasonForm, CALLOUT_REASON_SCHEMA, errorFields);
    setErrorFields(errorResult);
    setCallOutReasonText(value);
  };

  // Handles the save button modal confirmation
  const onSaveConfirm = () => {
    const requestData = {
      onCalls,
      noShows,
      shiftId: shiftDetails?.shiftId || NUMBER_0,
    };
    dispatch(updateNoshowAndOncall(requestData));
    showSaveButtonPopup(false);
  };
  // Method to handle the publish button click
  const publishButtonClick = () => {
    if (shiftDetails?.shiftId) {
      showPublishShiftPopup(false);
      dispatch(publishShift({ shiftIds: [shiftDetails?.shiftId] }));
    }
  };

  // Method to handle the unpublish button click
  const unPublishButtonClick = () => {
    if (shiftDetails?.shiftId) {
      showUnPublishShiftPopup(false);
      dispatch(unpublishShift({ shiftIds: [shiftDetails?.shiftId] }));
    }
  };

  // Method to handle the delete button click
  const onDelete = () => {
    if (shiftDetails?.shiftId) {
      showDeleteShiftPopup(false);
      dispatch(deleteShift({ shiftId: shiftDetails?.shiftId }));
    }
  };

  // Handle onCall checkbox change.
  const handleOnCallChange = (caId: number, isOnCall: boolean, colorCode: number) => {
    setOnCalls((prevOnCalls) => {
      const valueExists = prevOnCalls.some((entry) => entry.caId === caId);
      if (valueExists) {
        return prevOnCalls.filter((entry) => entry.caId !== caId);
      }
      return [...prevOnCalls, { caId, isOnCall, colorCode }];
    });
  };

  // Handle No Show changes.
  const handleNoShowChange = (
    caId: number,
    noShowStatus: number,
    noShowHour: string,
    checkInTime: string | null,
    checkOutTime: string | null,
    attempt1: string | null,
    attempt2: string | null,
  ) => {
    const noShowHourInNumber = Number(noShowHour);
    setNoShows((prevNoShows) => {
      const updatedNoShows = prevNoShows.filter((entry) => entry.caId !== caId);
      return [...updatedNoShows, { caId, noShowStatus, noShowHour: noShowHourInNumber, checkInTime, checkOutTime, attemptOne: attempt1, attemptTwo: attempt2 }];
    });
  };

  return (
    <div>
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href={AdminRoutePath.ManageSchedule}>{Strings.HD.ManageSchedules}</Breadcrumb.Item>
          <Breadcrumb.Item active>{Strings.Shift.ShiftDetails.Header}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="pageheader">
        <Row className="align-items-center">
          <Col>
            <h1>{Strings.Shift.ShiftDetails.Header}</h1>
          </Col>
          <Col xl="auto" lg="auto" md={12} className="text-end m-md-16">
            <div className="button-container-right">
              {!shiftDetails?.published && (
                <Button variant="outline-secondary" className="delete" onClick={onShiftDeleteClickHandler}>
                  <DeleteIcon />
                  {Strings.Button.Delete}
                </Button>
              )}
              {!shiftDetails?.published && (
                <Button variant="outline-secondary" onClick={onShiftEditClickHandler}>
                  <EditIcon />
                  {Strings.Button.Edit}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <ShiftDetailsCard
        shiftDetails={shiftDetails}
        type={params.type ? Number(params.type) : 0}
        caId={params.caId ? Number(params.caId) : 0}
        handleSaveClick={handleSaveButtonClick}
        onPublishButtonClick={() => {
          showPublishShiftPopup(true);
        }}
        onUnPublishButtonClick={() => {
          showUnPublishShiftPopup(true);
        }}
        caListCount={assignedCAList?.length}
        isLd={false}
      />
      <Row className="mt-3">
        {assignedCAList &&
          assignedCAList?.length > NUMBER_0 &&
          assignedCAList.map((caData: ICADetails) => (
            <Col xl={6} lg={6} md={12} key={caData.caId}>
              <EmployeeCard
                caDetails={caData}
                shiftDetails={shiftDetails}
                isFrom={NavigationToEmployeeCardFrom.Shift_Details_Page}
                onButtonClick={(caId: number, type: number) => onScheduleButtonClick(caId, type)}
                onNoShowChange={handleNoShowChange}
                onOnCallChange={handleOnCallChange}
              />
            </Col>
          ))}
      </Row>
      {/* Create shift popup */}
      {createShiftPopup && (
        <CreateShiftComponent
          show={createShiftPopup}
          handleClose={() => showCreateShiftPopup(false)}
          shift={shifts}
          shiftTypeDetails={shiftTypeDetails}
          isEdit
          shiftDetails={shiftDetails}
          shiftId={shiftDetails?.shiftId || 0}
        />
      )}

      <Modal show={publishShiftPopup} onHide={() => showPublishShiftPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.PublishPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showPublishShiftPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => publishButtonClick()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={unpublishShiftPopup} onHide={() => showUnPublishShiftPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.UnPublishPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showUnPublishShiftPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => unPublishButtonClick()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={unAssignPopup} onHide={() => showUnAssignPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.Scheduler.UnAssignPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showUnAssignPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onUnAssignClick()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={callOutPopup} onHide={() => showCallOutPopup(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{Strings.Shift.Button.CallOut}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-4">
          <Col className="mb-3">
            <KKISelect
              id="reasonTypes"
              label={Strings.Label.SelectReason}
              name="reasonTypes"
              className="custom-select"
              placeholder={Strings.Label.SelectReason}
              searchvalue={false}
              options={callOutReasonList}
              value={selectedReason}
              onSelectChange={onCalloutReasonSelect}
            />
          </Col>
          {selectedReason?.label === OTHER_REASON_LABEL && (
            <Col>
              <KKIInput
                id="declineReason"
                label={Strings.Label.EnterReason}
                name="declineReason"
                type="text"
                as="textarea"
                rows={3}
                placeholder={Strings.Leave.PlaceHolder.EnterReason}
                value={callOutReasonText}
                onChange={onCalloutReasonChange}
                alert={errorFields?.reason || ''}
              />
            </Col>
          )}
          <Col className="mt-4 text-end">
            <Button variant="outline-primary" type="button" onClick={() => showCallOutPopup(false)}>
              {Strings.Button.Cancel}
            </Button>
            <Button variant="primary" type="button" className="ms-3" onClick={() => onShiftCallOutClick()}>
              {Strings.Button.Submit}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      <Modal show={deleteShiftPopup} onHide={() => showDeleteShiftPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.DeleteShiftPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showDeleteShiftPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onDelete()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>

      <Modal show={saveButtonPopup} onHide={() => showSaveButtonPopup(false)} centered>
        <Modal.Body className="text-center py-5">
          <p>{Strings.Text.SaveDetailsConfirmPopText}</p>
          <Col className="mt-4">
            <Button variant="outline-primary" onClick={() => showSaveButtonPopup(false)}>
              {Strings.Button.No}
            </Button>
            <Button variant="primary" onClick={() => onSaveConfirm()} className="ms-3">
              {Strings.Button.Yes}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      {(isShiftDetailsLoading ||
        isPublishShiftLoading ||
        isDeleteShiftLoading ||
        isEditShiftLoading ||
        isCaListLoading ||
        isUnassignShiftLoading ||
        isCallOutShiftLoading ||
        isShiftTypesLoading ||
        isUpdateNoshowAndOncallLoading ||
        isUnPublishShiftLoading) && <Loader />}
    </div>
  );
};
export default ShiftDetails;
