/**
 * @file   src\store\slices\manageCallOutShiftSlice.ts
 * @brief  This file is responsible for call out shift management slices to call actions and state management.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { IManageCallOutShift } from '../../interfaces/callOutShiftInterface';
import { exportCallOutList, getCallOnCAList, getCallOutShifts, revertAllCalloutState, sendCallOnMessages } from '../actions/manageCallOutShiftAction';

// Initial state.
const manageCallOutState: IManageCallOutShift = {
  isFetchCallOutSuccess: false,
  isFetchCallOutLoading: false,
  fetchCallOutErrorCode: 0,
  fetchCallOutMessage: null,
  callOutShifts: null,
  isFetchCallOnCASuccess: false,
  isFetchCallOnCALoading: false,
  fetchCallOnCAErrorCode: 0,
  fetchCallOnCAMessage: null,
  callOnCAList: null,
  isSendCallOnSmsSuccess: false,
  isSendCallOnSmsLoading: false,
  sendCallOnSmsErrorCode: 0,
  sendCallOnSmsMessage: null,
  isExportCallOutSuccess: false,
  isExportCallOutLoading: false,
  exportCallOutErrorCode: 0,
  exportCallOutMessage: null,
  pdfData: null,
  notificationCount: 0,
};

// leave management slice.
export const manageCallOutShiftSlice = createSlice({
  name: 'manageCallOut',
  initialState: manageCallOutState,
  reducers: {
    resetCallOutState: () => manageCallOutState,
    updateNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
  },
  extraReducers(builder) {
    // ********************* Start of all call out shift listing action state handling. *********************
    // Get all call out shift listing default state.
    builder.addCase(getCallOutShifts.pending, (state) => {
      state.isFetchCallOutSuccess = false;
      state.isFetchCallOutLoading = true;
      state.fetchCallOutErrorCode = 0;
      state.fetchCallOutMessage = '';
    });
    //  Get all call out shift listing success state.
    builder.addCase(getCallOutShifts.fulfilled, (state, action) => {
      const { status, code, info } = action.payload;
      state.isFetchCallOutLoading = false;
      state.isFetchCallOutSuccess = true;
      state.fetchCallOutErrorCode = code;
      state.fetchCallOutMessage = status || '';
      state.callOutShifts = info?.shiftInfo || [];
    });
    //  Get all call out shift listing error state.
    builder.addCase(getCallOutShifts.rejected, (state, action: any) => {
      const { errcode, error } = action.payload ?? {};
      state.isFetchCallOutSuccess = false;
      state.isFetchCallOutLoading = false;
      state.fetchCallOutErrorCode = errcode;
      state.fetchCallOutMessage = error || '';
    });
    // ********************* End of all call out shift listing action state handling. *********************

    // ********************* Start of call on CA listing action state handling. *********************
    // Update call on CA listing default state.
    builder.addCase(getCallOnCAList.pending, (state) => {
      state.isFetchCallOnCASuccess = false;
      state.isFetchCallOnCALoading = true;
      state.fetchCallOnCAErrorCode = 0;
      state.fetchCallOnCAMessage = '';
    });
    //  Update call on CA listing success state.
    builder.addCase(getCallOnCAList.fulfilled, (state, action) => {
      const { status, code, info } = action.payload;
      state.isFetchCallOnCALoading = false;
      state.isFetchCallOnCASuccess = true;
      state.fetchCallOnCAErrorCode = code;
      state.fetchCallOnCAMessage = status || '';
      state.callOnCAList = info;
    });
    // Update call on CA listing error state.
    builder.addCase(getCallOnCAList.rejected, (state, action: any) => {
      const { error, errcode } = action.payload ?? {};
      state.isFetchCallOnCASuccess = false;
      state.isFetchCallOnCALoading = false;
      state.fetchCallOnCAErrorCode = errcode;
      state.fetchCallOnCAMessage = error || '';
    });
    // ********************* End of call on CA listing action state handling. *********************

    // ********************* Start of send call on messages to CA action state handling. *********************
    // Send call on messages to CA default state.
    builder.addCase(sendCallOnMessages.pending, (state) => {
      state.isSendCallOnSmsSuccess = false;
      state.isSendCallOnSmsLoading = true;
      state.sendCallOnSmsErrorCode = 0;
      state.sendCallOnSmsMessage = '';
    });
    //  Send call on messages to CA success state.
    builder.addCase(sendCallOnMessages.fulfilled, (state, action) => {
      const { status, code } = action.payload;
      state.isSendCallOnSmsLoading = false;
      state.isSendCallOnSmsSuccess = true;
      state.sendCallOnSmsErrorCode = code;
      state.sendCallOnSmsMessage = status || '';
    });
    //  Send call on messages to CA error state.
    builder.addCase(sendCallOnMessages.rejected, (state, action: any) => {
      const { error, errcode } = action.payload ?? {};
      state.isSendCallOnSmsSuccess = false;
      state.isSendCallOnSmsLoading = false;
      state.sendCallOnSmsErrorCode = errcode;
      state.sendCallOnSmsMessage = error || '';
    });
    // ********************* End of send call on messages to CA action state handling. *********************

    // ********************* Start of export call out shifts and CA list action state handling. *********************
    // Export call out shifts and CA list default state.
    builder.addCase(exportCallOutList.pending, (state) => {
      state.isExportCallOutSuccess = false;
      state.isExportCallOutLoading = true;
      state.exportCallOutErrorCode = 0;
      state.exportCallOutMessage = '';
    });
    //  Export call out shifts and CA list success state.
    builder.addCase(exportCallOutList.fulfilled, (state, action) => {
      state.isExportCallOutLoading = false;
      state.isExportCallOutSuccess = true;
      state.pdfData = action.payload;
      state.exportCallOutErrorCode = 0;
      state.exportCallOutMessage = null;
    });
    // Export call out shifts and CA list error state.
    builder.addCase(exportCallOutList.rejected, (state, action: any) => {
      const { error, errcode } = action.payload ?? {};
      state.isExportCallOutSuccess = false;
      state.isExportCallOutLoading = false;
      state.exportCallOutErrorCode = errcode;
      state.exportCallOutMessage = error || '';
    });
    // ********************* End of export call out shifts and CA list action state handling. *********************

    // ********************* Clear all callout states to initial sate action. *********************
    builder.addCase(revertAllCalloutState, (state) => {
      state.isFetchCallOutSuccess = false;
      state.isFetchCallOutLoading = false;
      state.fetchCallOutErrorCode = 0;
      state.fetchCallOutMessage = null;
      state.isFetchCallOnCASuccess = false;
      state.isFetchCallOnCALoading = false;
      state.fetchCallOnCAErrorCode = 0;
      state.fetchCallOnCAMessage = null;
      state.isSendCallOnSmsSuccess = false;
      state.isSendCallOnSmsLoading = false;
      state.sendCallOnSmsErrorCode = 0;
      state.sendCallOnSmsMessage = null;
      state.isExportCallOutSuccess = false;
      state.isExportCallOutLoading = false;
      state.exportCallOutErrorCode = 0;
      state.exportCallOutMessage = null;
    });
  },
});

// Export manage call out actions.
export const { resetCallOutState, updateNotificationCount } = manageCallOutShiftSlice.actions;
// Export reducer.
export default manageCallOutShiftSlice.reducer;
